<template>
  <div class="blog">
    <ScrollupSection />
    <div class="main overflow-hidden">
      <HeaderSection />
      <BreadcrumbSection  :background="back"/>
      <section id="blog" class="section blog-area ptb_50">
        <div class="container">
          <Blogs />
        </div>
      </section>
      <FooterSection />
      <ModalSearchSection />
      <ModalRegistro />
      <ModalMenuSection />
      <ModalSuscripcionStart/>

      <ModalListaAyunta />
      <ModalListaRP />
      <ModalListaParidad />
      <ModalPopUp />
      <ModalListaDocs />
      
    </div>
  </div>
</template>

<script>
import ScrollupSection from "../../Scrollup/Scrollup";
import HeaderSection from "../../Header/HeaderOne";
import BreadcrumbSection from "../../Breadcrumb/BlogThreeColumn";
import Blogs from "../BlogTwo/BlogTwo";
// import Pagination from '../Pagination/Pagination'
import FooterSection from "../../Footer/FooterThree";
import ModalSearchSection from "../../Modal/ModalSearch/ModalSearch";
// import ModalRegistro from '../../Modal/ModalSearch/ModalRegistro'
import ModalMenuSection from "../../Modal/ModalMenu/ModalMenu";
import ModalSuscripcionStart from "../../Modal/ModalPrime/ModalSuscripcionStart.vue"

import ModalListaAyunta from "../../Modal/ModalBlogList/ModalListaAyunta"
import ModalListaRP from "../../Modal/ModalBlogList/ModalListaRP"
import ModalListaParidad from "../../Modal/ModalBlogList/ModalListaParidad"
import ModalPopUp from "../../Modal/ModalAds/EmailPopUp"
//import ModalListaDocs from "../../Modal/ModalBlogList/ModalListaDocs.vue"
import ModalListaDocs from "../../Modal/ModalBlogList/ModalListaDocs_buscador.vue"



export default {
  name: "BlogThreeColumn",
  components: {
    ScrollupSection,
    HeaderSection,
    BreadcrumbSection,
    Blogs,
    ModalSuscripcionStart,
    ModalListaAyunta,
    ModalListaRP,
    ModalListaParidad,
    ModalPopUp,
    ModalListaDocs,
    // Pagination,
    FooterSection,
    ModalSearchSection,
    ModalMenuSection,
    ModalRegistro: () => import("../../Modal/ModalSearch/ModalRegistro"),
  },
  metaInfo: {
    title: "Electoral Tech & Law | Blog ",
    meta: [
      {
        name: "description",
        content: "Post con contenido revelante en material electoral",
      },
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      {
        property: 'og:title',
        content: 'ElectoralTech Blog, un sitio donde encontraras articulos relacinados con derecho electoral, temas juridicos relevantes',
        vmid: 'og:title'
      },
      {
        name: 'keywords',
        content: 'Electroal, Ley, Law, Formatos, Blog, Veracruz'
      },
      {
        name: 'author',
        content: 'Dr. José Olíveros '
      }
    ],
  },
  data(){
    return {
      back: "../../assets/fondos/blog.png"
    }

  },
  methods: {
    EmailPopUpModal(){
      const registered = window.sessionStorage.getItem("usuario");
      if(registered == null){
        const validation = window.sessionStorage.getItem("EmailPopUp");
        if(validation == null){
          window.$("#EmailPopUp").modal("show");
          /*console.log("Estpy dentro del if")*/
        }
        window.sessionStorage.setItem("EmailPopUp",true);
        console.log("El SESSION STORAGE:", validation);
      }      
    },
  },
  mounted() {
    this.EmailPopUpModal();
  },
};
</script>

<style>
</style>