<template>
    <div 
        class="modal fade"
        id="ModalListaAyunta"
        tabindex="-1"
        role="dialog"
        aria-labelledby="ModalListaAyunta"
        aria-hidden="true"
    >
    <!--
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title col-12 text-center textoTitulo">DISTRIBUCION DE REGIDORES POR EL PRNCIPIO DE REPRESETACION PROPORCIONAL, LISTA DE AYUNTAMIENTOS:</h5>
          </div>
            <div class="container-fluid d-flex justify-content-center mx-0">
                <div class="container">
                    <button type="button" class="list-group-item list-group-item-action active pl-3" disabled>
                        Lista De Ayuntamientos En Veracruz
                    </button>
                    <div v-for="n in 212" :key="n">
                        <button type="button" class="list-group-item list-group-item-action">{{n }} - Nombre Del Ayuntamiento </button>
                    </div>
                </div>
            </div>
        </div>
        </div> -->
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="row">
                <div class="modal-header">
                    <h5 class="modal-title col-12 text-center textoTitulo">SENTENCIAS DE PRIMERA INSTANCIA CON NULIDAD O CAMBIO DE GANADOR</h5>
                </div>
                <div class="container-fluid d-flex justify-content-center mx-0">
                    <div class="container">
                        <div v-for="(index, n) in sentenciasA" :key="n">
                            <button type="button" class="list-group-item list-group-item-action" @click="AbrirLink(index)">{{index.id }} - {{index.name}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="modal-header">
                    <h5 class="modal-title col-12 text-center textoTitulo">SENTENCIAS REVOCADAS, CONFIRMADAS O CUYA NULIDAD DECLARO LA SALA SUPERIOR</h5>
                </div>
                <div class="container-fluid d-flex justify-content-center mx-0">
                    <div class="container">
                        <div v-for="(index, n) in sentenciasB" :key="n">
                            <button type="button" class="list-group-item list-group-item-action" @click="AbrirLink(index)">{{index.id }} - {{index.name}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="modal-header">
                    <h5 class="modal-title col-12 text-center textoTitulo">ELECCIONES EXTRAORDINARIAS EFECTUADAS</h5>
                </div>
                <div class="container-fluid d-flex justify-content-center mx-0">
                    <div class="container">
                        <div v-for="(index, n) in sentenciasC" :key="n">
                            <button type="button" class="list-group-item list-group-item-action">{{index.id }} - {{index.name}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
//import {auth , fr} from "../../../firebase";


export default {
    name: "ModalListaAyunta",
    data (){
        return{
            sentenciasA: [
                //{id:"", name: "ELECCIONES ANULADAS EN EL PROCESO ELECTORAL 2021", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2FELECCIONES%20ANULADAS%20PROCESO%20ELECTORAL%202021%20ACTUALIZADO.pdf?alt=media&token=d2d3c71f-2a23-417a-852d-a598165f95fc"},
                {id: 1, name: "NEXTLALPÁN, EDO. DE MÉXICO - EL TRIBUNAL LOCAL DECLARA LA INVALIDEZ DE LA ELECCIÓN", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2FJI-11-2021%20sentencia%20Nextlalp%C3%A1n%2C%20Edo.%20de%20M%C3%A9xico.pdf?alt=media&token=1e3ac6ea-d81b-467a-9ae2-9c3365f95305"},
                {id: 2, name: "ZITACUARO, MICHOACÁN - LA SALA REGIONAL DECLARA NULIDAD DE LA ELECCIÓN", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2FST-JIN-0039-2021%20sentencia%20nulidad%20Zitacuaro%2C%20Mich..pdf?alt=media&token=3e118594-d245-48bf-b6c5-fad3389f6292"},
                {id: 3, name: "UAYMA, YUCATÁN - SALA XALAPA REVOCA LA SENTENCIA DEL TRIBUNAL LOCAL, DECLARA LA NULIDAD DE LA ELECCIÓN DE LA ELECCIÓN", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2FSX-JRC-0224-2021%20sentencia%20nulidad%20Uayma%2C%20Yuc..pdf?alt=media&token=cfd79853-c21c-4025-941d-5efcda8cdc23"},
                {id: 4, name: "EMILIANO ZAPATA, CHIAPAS - SALA REGIONAL XALAPA CONFIRMA LA RESOLUCIÓN DEL TRIBUNAL LOCAL", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2FSX_2021_JRC_231-sentencia%20de%20nulidad%20de%20Emiliano%20Zapata%2C%20Chis.pdf?alt=media&token=f95b0300-ebc6-4df6-b0cf-50bd921e367b"},
                {id: 5, name: "CHAHUITES, OAXACA - SALA XALAPA CONFIRMA LA SENTENCIA DEL TRIBUNAL LOCAL", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2FSX_2021_JRC_241-sentencia%20nulidad%20Chahuites%2C%20Oax.pdf?alt=media&token=51bf1f10-5dc7-48e8-901c-2a549207a552"},
                {id: 6, name: "JILOTLÁN DE LOS DOLORES, JALISCO - EL TRIBUNAL LOCAL DECLARA LA NULIDAD DE LA ELECCIÓN DE MUNÍCIPES", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2FJIN-085-2021%20sentencia%20nulidad%20Jilotl%C3%A1n%20de%20los%20Dolores%2C%20Jalisco.pdf?alt=media&token=bf84dad7-8616-47aa-8f2e-43546d8d7602"},
                {id: 6, name: "JILOTLÁN DE LOS DOLORES, JALISCO - SALA REGIONAL CONFIRMA LA NULIDAD DE ELECCIÓN", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2FSG_2021_JRC_251-SALA%20GUADALAJARA%20CONFIRMA%20LA%20NULIDAD%20DE%20LA%20ELECCI%C3%93N%20EN%20JILOTL%C3%81N%2C%20JAL.pdf?alt=media&token=a2321a0d-986c-45a9-82a2-34ab2b182882"},
                {id: 7, name: "CONSEJO DISTRITAL 01, BAJA CALIFORNIA SUR - SALA REGIONAL GUADALAJARA MODIFICA LOS RESULTADOS DE CASILLAS", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2FSG_2021_JIN_85-SENTENCIA%20MODIFICACI%C3%93N%20%20DE%20C%C3%93MPUTO%20Y%20CAMBIO%20DE%20GANADOR.pdf?alt=media&token=d09e31dc-9478-443a-b086-131b112b7a1c"},
                {id: 8, name: "AZCAPOTZALCO, CDMX - SALA REGIONAL MODIFICA LOS RESULTADOS DEL COMPUTO DE LA ELECCIÓN DE DIPUTACIONES FEDERALES DE MR", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2FSCM-JIN-0086-2021%20Azcapotzalco.pdf?alt=media&token=0f9d05b0-ddcb-493b-9ffa-f6ab6a1dc09c"},
                {id: 9, name: "DISTRITO ELECTORAL 19, TACÁMBARO - MICHOACÁN - SALA TOLUCA DECLARA LA NULIDAD DE LA ELECCIÓN", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2FST_2021_JRC_138-sentencia%20Tac%C3%A1mbaro.pdf?alt=media&token=65210a34-afe1-41f5-8961-80bc1a59c6c2"},
                {id: 10, name: "DISTRITO ELECTORAL 3 EN MARAVATÍO - MICHOACÁN - SALA TOLUCA REVOCA LA RESOLUCIÓN DEL TRIBUNAL LOCAL Y DECLARA LA NULIDAD DE LA ELECCIÓN ", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2FST_2021_JRC_56-sentencia%20Maravat%C3%ADo.pdf?alt=media&token=4de8015c-dd06-4813-a4a0-7d6ea80df53a"},
                
                {id: 11, name: "TECOMÁN, COLIMA - TRIBUNAL ELECTORAL DE COLIMA DECLARA LA NULIDAD DE LA ELECCIÓN", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2FJI-03-2021%20Y%20JI-04-2021%20ACUM%20TECOM%C3%81N%2C%20COLIMA.pdf?alt=media&token=31b67095-892a-49fc-bfd6-cfcd56f2dfca"},
                {id: 11, name: "TECOMÁN, COLIMA - SALA TOLUCA REVOCA LA NULIDAD DE ELECCIÓN DICTADA POR EL TRIBUNAL LOCAL", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2F11-10-2021%2FST_2021_JRC_169-SALA%20TOLUCA%20REVOCA%20LA%20NULIDAD%20DE%20ELECCI%C3%93N%20DICTADA%20POR%20EL%20TRIBUNAL%20LOCAL.pdf?alt=media&token=49fe0305-7846-4d72-b19a-1616bd00f029"},
                
                {id: 12, name: "GENERAL ZUAZUA, NUEVO LEÓN - TRIBUNAL ELECTORAL DE NUEVO LEÓN DECRETA LA NULIDAD DE LA ELECCIÓN", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2FJI-066-2021%20Y%20ACUM%20sentencia%20Monterrey%2C%20NL.pdf?alt=media&token=beb08b1d-617d-428c-a29b-d30c96600931"},
                {id: 12, name: "GENERAL ZUAZUA, NUEVO LEÓN - SALA MONTERREY CONFIRMA LA SENTENCIA DEL TRIBUNAL LOCAL", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2FSM_2021_JDC_827%20y%20JRC-203%20ACUM-CONFIRMA%20LA%20SENTENCIA%20DEL%20TRIBUNAL%20LOCAL%20QUE%20ANULA%20LA%20ELECCI%C3%93N%20DE%20GRAL.ZUAZUA.pdf?alt=media&token=df614acf-71d1-4235-9327-e2cad974fbd3"},
                
                {id: 13, name: "PANTEPEC, CHIAPAS - TRIBUNAL LOCAL DECLARA NULIDAD DE LA ELECCIÓN", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2FTEECH-JIN-M-090-2021-%20sentencia%20Chiapas%20Emiliano%20Zapata%20y%20El%20Parral.pdf?alt=media&token=b3e06aa1-b2f0-4663-96df-bcf5e4c3a2d5"},
                {id: 14, name: "BAHÍA DE BANDERAS, NAYARIT - TRIBUNAL ELECTORAL DE NAYARIT DECLARA LA NULIDAD DE LA ELECCIÓN", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2FTEE-JIN-16-2021%20sentencia%20Nayarit.pdf?alt=media&token=c3a24d54-a00e-43bb-9587-41c7b1dddd6b"},
                {id: 15, name: "PUEBLA - SALA REGIONAL MODIFICA Y DEJA SIN EFECTOS LA NULIDAD DE LA ELECCIÓN",url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2FPUEBLA.SCM-JRC-0263-2021.%20SALA%20REGIONAL%20MODIFICA%20Y%20DELA%20SIN%20EFECTOS%20LA%20NULIDAD%20DE%20LA%20ELECCI%C3%93N..pdf?alt=media&token=2b6ae1b5-314e-4094-974e-1a92b625b27b"},
                {id: 16, name: "LANDERO Y COSS, VERACRUZ - TRIBUNAL ELECTORAL LOCAL, CONFIRMA LA DECLARACIÓN DE VALIDEZ Y DECLARA GANADOR A VERACRUZ VA",url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2FTEV-RIN-3-2021-Y-ACUM-TEV-RIN-38-2021-INC-1-RESOLUCI-N-INCIDENTAL%20veracruz.pdf?alt=media&token=a0248cdd-f9b5-4a57-863a-c200ea30cd76"},
                {id: 17, name: "SANTA CRUZ GUADALUPE, TLAXCALA - TRIBUNAL LOCAL DECLARA LA NULIDAD DE LA ELECCIÓN", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2F01-10-2021%2FTET-JE-174-2021-STA.CRUZ.GPE.%20POR%20ACTOS%20DE%20VIOLENCIA.TRIBUNAL%20LOCAL.pdf?alt=media&token=55c21a69-2f31-461d-b46a-4f7b6897f22a"},
                {id: 18, name: "GUADALUPE VICTORIA, TLAXCALA - TRIBUNAL LOCAL DECLARA LA NULIDAD DE LA ELECCIÓN", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2F01-10-2021%2FTET-JDC-106-2021-GPE.VICTORIA-POR%20ACTOS%20DE%20VIOLENCIA.TRIBUNAL%20LOCAL.pdf?alt=media&token=97dfa8fa-af06-44cc-971f-dcc6aaa9f695"},
                {id: 19, name: "CANDELARIA TEOTLALPAN, TLAXCALA - TRIBUNAL LOCAL DECLARA EMPATE", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2F01-10-2021%2FJDC-143-2021-CANDELARIA%20TEOTLALPAN-EMPATE-TRIBUNAL%20LOCAL.pdf?alt=media&token=2fd2ec35-cbca-4745-bc68-a3add78df4a6"},
                {id: 20, name: "VILLA GUERRERO - TRIBUNAL LOCAL REVOCA EL ACUERDO DE REGIDURIAS POR RP", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2F06-10-2021%2FJDCL-418-2021%20y%20Acum.%20TRIBUNAL%20LOCAL%20REVOCA%20EL%20ACUERDO%20DE%20ASIGNACI%C3%93N%20DE%20RIGIDURIAS%20POR%20RP%20VILLA%20GUERREO_.pdf?alt=media&token=bc2f30df-440b-49ea-8ff7-8ae83a1e4a3d"},
                {id: 21, name: "TEPEYAHUALCO, PUEBLA - TRIBUNAL LOCAL DECLARA LA NULIDAD DE LA ELECCIÓN", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2F06-10-2021%2FTEEP-JDC-188-2021-TRIBUNAL%20LOCAL%20DECLARA%20LA%20NULIDAD%20DE%20LA%20ELECCI%C3%93N%20DE%20DE%20TEPEYAHUALCO%2C%20PUEBLA.pdf?alt=media&token=2556dc28-48d2-4c8a-ac1d-258b465eb312"},
                {id: 22, name: "ILIATENCO, GUERRERO - SALA REGIONAL REVOCA LA RESOLUCIÓN DEL TRIBUNAL LOCAL Y DECLARA LA NULIDAD DE LA ELECCIÓN", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2F06-10-2021%2FSCM_2021_JRC_225-SALA%20CDMX%20REVOCA%20LA%20RESOLUCI%C3%93N%20DEL%20TRIBUNAL%20DE%20GUERRERO%20Y%20DECLARA%20LA%20NULIDAD%20DE%20LA%20ELECCI%C3%93N%20DEL%20AYTO.%20DE%20ILIATENCO.pdf?alt=media&token=6ef5fb8a-2ee8-4031-87c0-3f21f325c886"},
                {id: 23, name: "POCHUTLA, OAXACA - TRIBUNAL LOCAL DECLARA LA NULIDAD DE ELECCIÓN", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2F18-10-2021%2FRIN-EA-101-2021-TRIBUNAL%20LOCAL%20DECLARA%20LA%20NULIDAD%20DE%20ELECCI%C3%93N%20EN%20POCHUTLA%2C%20OAX.pdf?alt=media&token=d668b5f1-9798-4c27-8e3f-0f7a56cd0fdb"},
                {id: 24, name: "AYUQUILILLA, OAXACA - TRIBINAL LOCAL DECLARO LA NULIDAD DE ELECCIÓN", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2F18-10-2021%2FRIN-EA-38-2021-TRIBINAL%20LOCAL%20DECLARO%20LA%20NULIDAD%20POR%20VIOLENCIA%20Y%20QUEMA%20DE%20DOC.%2C%20AYUQUILILLA%2C%20OAX_.pdf?alt=media&token=81acc39c-6b7a-4121-808f-022e85fb785d"},
                

                {id: 25, name: "CHILTEPEC, OAXACA - TRIBUNAL LOCAL DECLARA LA NULIDAD DE LA ELECCIÓN", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2F18-10-2021%2FRIN-EA-97-2021-TRIBUNAL%20LOCAL%20DECLARA%20LA%20NULIDAD%20DE%20LA%20ELECCI%C3%93N%20POR%20VIOLENCIA%20Y%20QUEMA%20DE%20DOC.%2CCHILTEPEC%2C%20OAX_.pdf?alt=media&token=d9b32b1c-d803-416a-a954-5a71b9443260"},                
                {id: 25, name: "CHILTEPEC, OAXACA - SALA REGIONAL REVOCA LA SENTENCIA IMPUGNADA, CONFIRMANDO LOS RESLTADOS DEL ACTA Y LA DECLARACIÓN DE VALIDEZ", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2F04-011-2021%2FSX_2021_JRC_510-SALA%20REGIONAL%20REVOCA%20LA%20SENTENCIA%20IMPUGNADA%2C%20CONFIRMANDO%20LOS%20RESLTADOS%20DEL%20ACTA%20Y%20LA%20DECLARACI%C3%93N%20DE%20VALIDEZ.pdf?alt=media&token=7dd500b4-1818-4f42-8b73-decece3c9fb1"},

                {id: 26, name: "CHICONAMEL, VERACRUZ - SALA REGIONAL DECLARA LA NULIDAD DE LA ELECCIÓN", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2F26-10-2021%2FSX_2021_JRC_465-NULIDAD%20CHICONAMEL.pdf?alt=media&token=d0ffc16f-9753-4499-9160-ca638a1e87ac"},
                
                {id: 27, name: "JESUS CARRANZA, VERACRUZ - TRIBUNAL LOCAL DECLARA LA NULIDAD DE LA ELECCIÓN", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2F26-10-2021%2FTEV-RIN-286-2021-Y-ACUMULADOS-SENTENCIA-TEV%20DECLARA%20LA%20NULIDAD%20DE%20LA%20ELECCI%C3%93N%20DE%20EDILES%20DE%20JESUS%20CARRANZA%2C%20VER%20(1).pdf?alt=media&token=c349306a-b721-4ca0-b2f9-b8df1ba3ef5d"},
                {id: 27, name: "JESUS CARRANZA, VERACRUZ - SALA REGIONAL CONFIRMA LA SENTÈNCIA DEL TRIBUNAL LOCAL", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2F26-10-2021%2FSUP_2021_REC_1981-SALA%20SUPERIOR%20CONFIRMA%20LA%20SENTENCIA%20DEL%20TRIBUNAL%20LOCAL%20REVOCANDO%20LA%20NULIDAD%20DICTADA%20POR%20LA%20SALA%20REGIONAL%20(1).pdf?alt=media&token=6e5a0184-8558-4b92-a681-98627b951aef"},

                {id: 28, name: "TEPOTZOTLÁN, EDO DE MÉXICO - TRIBUNAL LOCAL DECLARA LA NULIDAD DE LA ELECCIÓN", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2F11-11-2021%2FJI072021Acum-NULIDAD%20TEPOZTLAN%2C%20EDO%20DE%20MEXICO.pdf?alt=media&token=f29ce1d6-ea98-456d-b2e2-3e281cb62e11"},
                //{id: 17, name: "", url: ""},
            ],
            sentenciasB: [
                {id: 1, name: "ZITACUARO, MICHOACÁN - SALA SUPERIOR REVOCÓ NULIDAD Y CONFIRMA RESULTADOS DEL ACTA ", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2FSUP_2021_REC_1159-Sentencia%20Zit%C3%A1cuaro%2C%20Mich..pdf?alt=media&token=6234c191-b55b-4d5c-9b61-bc36a7a1e7dc"},
                {id: 2, name: "UAYMA, YUCATÁN - SALA SUPERIOR DESECHÓ", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2FSUP_2021_REC_1178-sentencia.pdf?alt=media&token=85bab590-c41d-492f-88b4-47e272195c78"},
                {id: 3, name: "EMILIANO ZAPATA, CHIAPAS - SALA SUPERIOR DESECHÓ", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2FSUP_2021_REC_1241-sentencia%20Emiliano%20Zapata%20Sala%20Superior.pdf?alt=media&token=370488e1-ee90-4660-a238-4818a2bbec4f"},
                {id: 4, name: "CHAHUITES, OAXACA - SALA SUPERIOR DESECHÓ", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2FSUP_2021_REC_1293-SE%20DESECHA%20DE%20PLANO%20EL%20RECURSO.pdf?alt=media&token=c0e7aeaa-869d-4268-9a86-cb68fa4bda66"},
                //{id: 6, name: "JILOTLÁN DE LOS DOLORES, GUADALAJARA.", url: ""},
                {id: 5, name: "CONSEJO DISTRITAL 01, BAJA CALIFORNIA SUR - SALA SUPERIOR CONFIRMA MODIFICACIÓN DE RESULTADOS", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2FSUP_2021_REC_1059-sentencia%20BCS.pdf?alt=media&token=40675c5c-f53c-4a38-a19e-b203f9808019"},
                {id: 6, name: "AZCAPOTZALCO, CDMX - SALA SUPERIOR REVOCÓ Y CAMBIÓ GANADOR", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2FSUP_2021_REC_1011-SENTENCIA%20AZCAPOTZALCO.pdf?alt=media&token=0e5d2d9c-de7d-4464-aac8-b92bc3bf2c0a"},
                {id: 7, name: "DISTRITO ELECTORAL 19, TACÁMBARO - MICHOACÁN - SALA SUPERIOR CONFIRMA LA VALIDEZ DE LA ELECCIÓN", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2FSUP_2021_REC_1323-SENTENCIA%20QUE%20CONFIRMA%20LA%20VALIDEZ%20DE%20LA%20ELECCI%C3%93N%20Y%20EL%20OTORGAMIENTO%20DE%20LA%20CONSTANCIA%20DE%20MAYOR%C3%8DA%20EN%20EL%20DISTRITO%2019%20EN%20TAC%C3%81MBARO%2C%20MICH.pdf?alt=media&token=2c95e20f-c385-4654-ac56-b46726422509"},
                {id: 8, name: "DISTRITO ELECTORAL 3 EN MARAVATÍO - SALA SUPERIOR REVOCÓ NULIDAD DE LA ELECCIÓN", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2FSUP_2021_REC_1329-MARAVAT%C3%8DO.%20REVOCA%20LA%20NULIDAD.pdf?alt=media&token=6d524e33-3e41-4a16-a178-a720e1123eeb"},
                //{id: 11, name: "TECOMÁN, COLIMA", url: ""},
                {id: 9, name: "TAMAULIPAS - SALA SUPERIOR MODIFICA Y REVOCA LA ASIGNACIÓN DE DIPUTACIONES POR RP", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2F06-10-2021%2FSUP_2021_REC_1890-SALA%20SUPERIOR%20MODIFICA%20Y%20REVOCA%20ASIGNACI%C3%93N%20DE%20DIP%20RP.pdf?alt=media&token=b5dd0203-2fa2-423c-8c27-9d23ea36cb09"},
                {id: 10, name: "CHIAPAS - SALA REGIONAL REVOCA Y DEJA SIN EFECTOS LA SENTENCIA DICTADA POR EL TRIBUNAL LOCAL", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2FSX_2021_JRC_360-NULIDAD%20DE%20VOTACI%C3%93N%20EN%20CASILLAS.%20SX%20CONFIRMA%20EL%20C%C3%93MPUTO%20Y%20REVOCA%20LA%20CONSTANCIA%20DE%20MAYORIA%20(CAMBIO%20DE%20GANADOR)%20(1).pdf?alt=media&token=d0e49719-4c9c-4b4c-8d51-5ebe442a1488"},
                {id: 11, name: "BAHÍA DE BANDERAS, NAYARIT - SALA SUPERIOR REVOCÓ NULIDAD", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2FSG_2021_JRC_227-sentencia%20Nayarit%20Sala%20Superior%20Revoca%20la%20nulidad.pdf?alt=media&token=fdf78651-1742-418a-a04d-0e5d0fc28ae7"},
                {id: 12, name: "STA. CRUZ XOXOCOTLÁN, OAXACA - SALA XALAPA DECLARA NULIDAD DE ELECCIÓN DE CONCEJALÍAS", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2FSX_2021_JDC_1338-DECLARA%20LA%20NULIDAD%20DE%20ELECCI%C3%93N%20DE%20CONCEJAL%C3%8DAS%20AYTO.%20DE%20STA.%20CRUZ%20XOXOCOTL%C3%81N%2C%20OAX.pdf?alt=media&token=89b71c23-6c27-484a-8284-89d52b6b41b4"},
                {id: 13, name: "JOLALPÁN, PUEBLA - SALA  SUPERIOR REVOCA LA NULIDAD DICTADA POR SALA REGIONAL DE CDMX", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2F18-10-2021%2FSUP_2021_REC_1962-SALA%20%20SUPERIOR%20REVOCA%20LA%20NULIDAD%20DICTADA%20POR%20SALA%20REGIONAL%20DE%20CDMX.pdf?alt=media&token=ff54527c-32b7-49f6-b992-786036fa8485"},
                {id: 14, name: "TLAQUEPAQUE, JALISCO - SALA SUPERIOR REVOCA LA SENTENCIA DE LA REGIONAL Y DECLARA LA NULIDAD DE LA ELECCIÓN", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2F18-10-2021%2FSUP_2021_REC_1874-TLAQUEPAQUE%2C%20JAL.%20SALA%20SUPERIOR%20REVOCA%20LA%20SENTENCIA%20DE%20LA%20REGIONAL%20Y%20DECLARA%20LA%20NULIDAD%20DE%20LA%20ELECCI%C3%93N%20DEL%20AYUNTAMIENTO%20(1).pdf?alt=media&token=0f38fc49-7cb2-4a4d-b7d2-8a6292cbcbea"},
                {id: 15, name: "ILIATENCO, GUERRERO - SALA SUPERIOR CONFIRMA LA SENTENCIA DE SALA REGIONAL", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2F06-10-2021%2FSUP_2021_REC_1861-SALA%20SUPERIOR%20CONFIRMA%20LA%20SENTENCIA%20DE%20LA%20REGIONAL%20QUE%20ANULO%20POR%20VPG.pdf?alt=media&token=bc148283-5848-461c-bebc-d403d0b73537"},
                {id: 16, name: "LA FRAGUA, PUEBLA - SALA SUPERIOR CONFIRMA LA SENTENCIA DEL TRIBUNAL LOCAL REVOCANDO LA NULIDAD DICTADA POR LA SALA REGIONAL", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2F26-10-2021%2FSUP_2021_REC_1981-SALA%20SUPERIOR%20CONFIRMA%20LA%20SENTENCIA%20DEL%20TRIBUNAL%20LOCAL%20REVOCANDO%20LA%20NULIDAD%20DICTADA%20POR%20LA%20SALA%20REGIONAL%20(1).pdf?alt=media&token=6e5a0184-8558-4b92-a681-98627b951aef"},
                {id: 17, name: "LANDERO Y COSS, VERACRUZ - SALA SUPERIOR DESECHÓ",url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2F04-011-2021%2FSUP_2021_REC_2013-SALA%20SUPERIOR%20DESECHA%20DE%20PLANO%20AL%20NO%20UBICARSE%20EL%20ALGUNO%20DE%20LOS%20SUPUESTOS%20DE%20PROCEDENCIA%20DEL%20REC%2C%20DEJANDO%20SUBSISTENTE%20LA%20SENTEN.pdf?alt=media&token=9bf29896-484f-4d47-b7c6-84d234a5670a"},
                
                {id: 18, name: "CHICONAMEL, VERACRUZ - SALA SUPERIOR DESECHÓ",url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2F11-11-2021%2FSUP_2021_REC_2024-SALA%20SUPERIOR%20DESECHA%20POR%20NO%20ACTUALIZARSE%20EL%20REQUISITO%20ESPECIAL%20DE%20PROCEDENCIA.pdf?alt=media&token=deb00aab-a9a4-4a1e-99f7-dcae87c17935"},
                {id: 19, name: "JESUS CARRANZA, VERACRUZ - SALA SUPERIOR DESECHÓ",url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2F11-11-2021%2FSUP_2021_REC_2049-SALA%20SUPERIOR%20DESECHA%20POR%20NO%20ACTUALIZAR%20EL%20REQUISITO%20ESPECIAL%20DE%20PROCEDENCIA.pdf?alt=media&token=a88444bc-fcc7-46fd-bf1a-75dbfc30794b"},
                
                {id: 20, name: "SALINA CRUZ, OAXACA - SALA REGIONAL DECLARA LA NULIDAD DE ELECCIÓN",url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2F01-10-2021%2FSX_2021_JRC_518-SALA%20XALAPA%20DECLARA%20LA%20NULIDAD%20DE%20LA%20ELECCI%C3%93N%20DE%20SALINA%20CRUZ%2C%20OAXACA%20(1).pdf?alt=media&token=6c0868ee-bf8b-4579-8cd7-20de7aa83d27"},
                //{id: 16, name: "VERACRUZ, LANDERO Y COSS", url: ""}, 
            ],

            sentenciasC: [
                {id: 1, name: "GENERAL ZUAZUA, NUEVO LEÓN - JORNADA ELECTORAL EXTRAORDINARIA: 07-11-2021", url: ""},
                {id: 2, name: "TLAQUEPAQUE, JALISCO - JORNADA ELECTORAL EXTRAORDINARIA: 21-11-2021", url: ""},
                
            ],
            SelectedOption: "",
            captcha: false,
            urlProd:
                "https://us-central1-electoraltech-d9cec.cloudfunctions.net/webApi/",
            urlLocal:
                "http://localhost:5001/electoraltech-d9cec/us-central1/webApi/",
        }
    },
    computed: {
        url() {
        return process.env.NODE_ENV == "development"
            ? this.urlLocal
            : this.urlProd; 
        },
    },

    methods: {

        AbrirLink(data){
            window.open(data.url);
        },    
    },
    
}
</script>

<style scoped>
.modal-body {
  display: block !important;
}

.btnCancel {
  padding: 9px;
  font-size: 14px;
  border: none;
  border-radius: 18px;
  margin: 2px;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');

body {
    background: #F5F1EE;
    font-family: 'Roboto', sans-serif
}

.price-text{
    font-family: 'Roboto';
    font-size: 1.2rem;
    font-weight: 800;
    background: -webkit-linear-gradient(#cf1773,#9b32a8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
     color: tomato;
}

.card {
    width: 250px;
    border-radius: 10px
}

.card-img-top {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px
}

span.text-muted {
    font-size: 12px
}

small.text-muted {
    font-size: 8px
}

h5.mb-0 {
    font-size: 1rem
}

small.ghj {
    font-size: 9px
}

.mid {
    background: #ECEDF1
}

h6.ml-1 {
    font-size: 13px
}

small.key {
    text-decoration: underline;
    font-size: 9px;
    cursor: pointer
}

.btn-danger {
    color: #FFCBD2
}

.btn-danger:focus {
    box-shadow: none
}

small.justify-content-center {
    font-size: 9px;
    cursor: pointer;
    text-decoration: underline
}

@media screen and (max-width:600px) {
    .col-sm-4 {
        margin-bottom: 50px
    }
}
.container{
    height:450px;
    overflow-y: auto;
}

.textoTitulo{
    font-size: 2rem;
    font-family: 'Roboto', sans-serif;
    background: -webkit-linear-gradient(#cf1773,#9b32a8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
     color: tomato;

}

</style>

