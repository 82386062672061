<template>
  <div class="container">
      <div class="filtros">
        <div class="d-flex justify-content-center mb-5">
          <h4 class="btn" @click="AbrirDocs()">Documentos relevantes</h4>
        </div>  

        <div class="d-flex justify-content-center" >
          <div class="encabezados">
            <ul class="nav nav-pills mb-5" id="pills-tab" role="tablist">
              <!--
              <li class="nav-item" role="presentation">
                <a class="nav-link active" id="pills-all-post" data-toggle="pill" href="#pills-all-post" role="tab"
                    aria-controls="pills-all-post" aria-selected="true" @click="SelectType(0)">
                  Todos
                </a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="nav-link" id="pills-newArti" data-toggle="pill" href="#pills-newArti" role="tab"
                    aria-controls="pills-newArti" aria-selected="false" @click="SelectType(1)">
                  Artículos Recientes
                </a>
              </li>-->
              <li class="nav-item" role="presentation">
                <a class="nav-link active" id="pills-ss" data-toggle="pill" href="#pills-ss" role="tab"
                    aria-controls="pills-ss" aria-selected="true" @click="SelectType(2)">
                  Sesiones Sala Superior
                </a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="nav-link" id="pills-sx" data-toggle="pill" href="#pills-sx" role="tab"
                    aria-controls="pills-sx" aria-selected="false" @click="SelectType(3)">
                  Sesiones Sala Xalapa
                </a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="nav-link" id="pills-tev" data-toggle="pill" href="#pills-tev" role="tab"
                    aria-controls="pills-tev" aria-selected="false" @click="SelectType(4)">
                  Sesiones TEV
                </a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="nav-link" id="pills-otros" data-toggle="pill" href="#pills-otros" role="tab"
                    aria-controls="pills-otros" aria-selected="false" @click="SelectType(5)">
                  Otros
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

    <div class="row">

      <div class="col-12 col-md-6 col-lg-4" v-if="primeIndicator==false">
        <div class="single-blog res-margin">
          <div class="blog-thumb">
            <img :src="href='https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/blog%2FSuscribeteElectoral.jpg?alt=media&token=6ee184ec-7818-443f-a601-ace12511f486'" />
          </div>
          <div class="blog-content">
            <ul class="meta-info d-flex justify-content-between">
            </ul>
            <h3 class="blog-title my-3" >Suscribete a ElectoralTech & Legal</h3>
            <span

              ></span
            >
            <p>
              Conoce la proyección de lo que podría ser la próxima integración del Congreso de Veracruz. Además, descubre los detalles de los ajustes por partido que podrían darse con el fin de garantizar la paridad en la próxima integración del Congreso.
            </p>
            <a  class="blog-btn mt-3" style="color:#eb34cf; cursor: pointer" @click="alertPrime()" >SUSCRIBIRME</a>
          </div>
        </div>
      </div>
      <!--
      <div class="col-12 col-md-6 col-lg-4" v-if="primeIndicator==true">
        
        <div class="single-blog res-margin">
         
          <div class="blog-thumb">
            <img :src="href='https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/blog%2FRP%20AYUNTAMIENTOS%20VERACRUZ.png?alt=media&token=04270621-3f42-4d05-a961-ea7bce549067'" />
          </div>
          
          <div class="blog-content">
           
            <ul class="meta-info d-flex justify-content-between">
            </ul>
            
            <h3 class="blog-title my-3" >Distribución de regidores por el principio de representación proporcional en los Ayuntamientos de  Veracruz</h3>
            <span

              ></span
            >
            <p>
              Revisa en la lista de 212 Ayuntamientos, encuentra el de tu interés y consulta los resultados de su distribución. 
            </p>
            <a  class="blog-btn mt-3" style="color:#eb34cf; cursor: pointer" @click="showAyuntamientosList()" >CONSULTAR LISTA</a>
          </div>
        </div>
      </div>
      -->

      <div class="col-12 col-md-6 col-lg-4" v-for="p in posts" :key="p.id">
        <!-- Single Blog -->
        <div class="single-blog res-margin">
          <!-- Blog Thumb -->
          <div class="blog-thumb">
            <img :src="p.image" :alt="p.titulo" />
          </div>
          <!-- Blog Content -->
          <div class="blog-content">
            <!-- Meta Info -->
            <ul class="meta-info d-flex justify-content-between">
              <li></li>
              <li>{{ fechaFormato(p.fechaRegistro) }}</li>
            </ul>
            <!-- Blog Title -->
            <h3 class="blog-title my-3">{{ p.titulo }}</h3>
            <span
              class="badge badge-secondary badge-pill"
              style="margin: 3px"
              v-for="cat in p.categorias"
              :key="cat.id"
              >{{ cat.texto }}</span
            >
            <p>
              {{ p.descripcion }}
            </p>
            <a class="blog-btn mt-3" style="cursor: pointer" @click="verBlog(p.id)">Lee más</a>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="single-blog res-margin">
          <div class="blog-thumb">
            <img src="https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FNULIDADES-ELECCION-AGOSTO2021%2FPost-nulidades%20(1).png?alt=media&token=d4d018b3-76ef-4e4c-8101-d7ebc6956d91" alt=""/>
          </div>
          <div class="blog-content">
            <ul class="meta-info d-flex justify-content-between">
            </ul>
            <h3 class="blog-title my-3" >Sentencias con nulidad de elección</h3>
            <span

              ></span
            >
            <p>
              Conoce las nulidades de elección a nivel federal o local, confirmadas o revocadas.
            </p>
            <a  class="blog-btn mt-3" style="color:#14fc03; cursor: pointer" @click="showAyuntamientosList()" >Ver Lista</a>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="single-blog res-margin">
          <div class="blog-thumb">
            <img src="https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FLISTAAYUNTAMIENTOS%2Fsentencias-ad-list%20(1).png?alt=media&token=484efd8e-d69a-491f-ba44-46e953acad4d" alt=""/>
          </div>
          <div class="blog-content">
            <ul class="meta-info d-flex justify-content-between">
            </ul>
            <h3 class="blog-title my-3" >Sentencias de asignación por RP para Diputados y Ayuntamientos</h3>
            <span

              ></span
            >
            <p>
              Conoce las sentencias de asignación RP diputados y ayuntamientos.
            </p>
            <a  class="blog-btn mt-3" style="color:#14fc03; cursor: pointer" @click="showRPList()" >Ver Lista</a>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="single-blog res-margin">
          <div class="blog-thumb">
            <img src="https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FLISTA-PARIEDAD%2Fsentencias-par-ad-list%20(1).png?alt=media&token=58929e26-f634-486f-83a4-e624e7f2eac7" alt=""/>
          </div>
          <div class="blog-content">
            <ul class="meta-info d-flex justify-content-between">
            </ul>
            <h3 class="blog-title my-3" >Sentencias sobre paridad en la cámara de diputados federales y locales</h3>
            <span

              ></span
            >
            <p>
              Conoce las sentencias sobre paridad en la cámara de diputados federales y locales.
            </p>
            <a  class="blog-btn mt-3" style="color:#14fc03; cursor: pointer" @click="showParidadList()" >Ver Lista</a>
          </div>
        </div>
      </div>

      <!--
      <div class="col-12 col-md-6 col-lg-4">
        <div class="single-blog res-margin">
          <div class="blog-thumb">
            <img src="https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-LOGOS%2Fsentencias-acciones-afirma-ad-list.png?alt=media&token=8ba7c44e-3b36-4847-ae2b-cae58498896d" alt=""/>
          </div>
          <div class="blog-content">
            <ul class="meta-info d-flex justify-content-between">
            </ul>
            <h3 class="blog-title my-3" >Sentencias sobre acciones afirmativas en la cámara de diputados federales y locales</h3>
            <span
              ></span
            >
            <p>
              Conoce las sentencias acciones afirmativas en la cámara de diputados federales y locales.
            </p>
            <a  class="blog-btn mt-3" style="color:#14fc03; cursor: pointer" @click="showRPList()" >Ver Lista</a>
          </div>
        </div>
      </div> 
      -->

    </div>
    <Pagination
      :pagination="paginationList"
      :next="next"
      :previous="previous"
      class="mb-5"
    />
    
  </div> 
</template>

<script>
import Pagination from "../Pagination/Pagination";
import { fr } from "../../../firebase";
import fn from '../../../libs/index';
import {auth} from "../../../firebase";
import { mapMutations } from "vuex";



export default {
  name: "BlogTwo",
  components: {
    Pagination,
  },
  mounted() {
    this.obtenerIdicadorPrime();
    this.obtenerPaginacion();
    this.obtenerBlogs(0,true);
    this.actualizarUsuario();
    this.alertPrime();
  },
  data() {
    return {
      pivotDocument: null,
      paginationList: [],
      posts: [],
      postsPrime: [],
      lastPost: null,

      limit: 6,
      pagActual: 0,
      totalPag: 0,
      firstPosts: [],

      subscribePost:[],
      primeIndicator: false,
      selectedType : 0,
    };
  },
  methods: {
    ...mapMutations({
      setUsuario: "auth/setUsuario",
    }),
    async actualizarUsuario(){
      if ( auth.currentUser != null ) {
        const actualizaInfo = await fr
        .collection("usuarios")
        .doc(auth.currentUser.uid)
        .get()
        this.setUsuario(actualizaInfo.data())
        window.location.reload();
      }
    },

    AbrirDocs(){
      window.$("#ModalListaDocs").modal("show");
    },

    async SelectType(id){
      //ID's ---> 0 .- Todos , 1.- Articulos Recientes , 2 .- Sala Xalapa , 3 .- Tribunal Electoral De Veracruz 4 .- Otros
      this.selectedType = id;
      this.lastPost = null;
      this.firstPosts = [];
      this.obtenerBlogs(id,true);
      },

    showListaNulidades(){

    },

    
    async alertPrime() {
      // window.$("#ModalCaptcha").modal("show");
      if ( auth.currentUser != null ) {
          this.suscribeModal();
      } else {
          const result = await this.$swal({
          icon: "info",
          title: "Registrate o Inicia sesión!",
          text: "Antes de convertirte en un miembro del servicio de información de ElectoralTech crea tu cuenta, es Gratis!",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showCancelButton: true,
          confirmButtonText: 'Crear cuenta ahora',
          });
          if (result.isConfirmed) {
          window.$("#registro").modal('show');
          } else if (result.isDenied) {
          this.$router.push({ name: "inicio" });
          console.log('no confirmo')
          }
      }
    },

    showAyuntamientosList(){
      window.$("#ModalListaAyunta").modal("show");
    },
    showRPList(){
      window.$("#ModalListaRP").modal("show");
    },
    showParidadList(){
      window.$("#ModalListaParidad").modal("show");
    },

    suscribeModal(){
      window.$("#ModalPrime").modal("show");
    },
    
    verBlog(id){
      sessionStorage.setItem("currentBlog", id);
      this.$router.push({name:'blogDesc',});
    },
    fechaFormato(mil){
      return fn.fechaFormato(mil);
    },
    obtenerIdicadorPrime(){
      if(sessionStorage.getItem("usuario")){
        const userData = JSON.parse(sessionStorage.getItem("usuario"));
        if(userData.prime==1){
          this.primeIndicator = true;
        }
        console.log("TU IDICADOR PRIME: "+ this.primeIndicator);
      }
    },
    next() {
      if ((this.pagActual + 1) < this.totalPag) {
        this.pagActual++;
        this.obtenerBlogs(this.selectedType,true);
        this.paginationList.forEach((pag) => {
          if (pag.id == this.pagActual) {
            pag.active = true;
          } else {
            pag.active = false;
          }
        });
      }
    },
    previous() {
      if (this.pagActual > 0) {
        this.obtenerBlogs(this.selectedType,false);
        this.pagActual--;
        this.paginationList.forEach((pag) => {
          if (pag.id == this.pagActual) {
            pag.active = true;
          } else {
            pag.active = false;
          }
        });
      }
    },
    async obtenerPaginacion() {
      const querySnapshot = await fr.collection("postCounter").limit(1).get();
      const totalBlog = querySnapshot.docs[0];
      this.totalPag = Math.ceil(totalBlog.data().blog / this.limit);
      // console.log(totalPag);
      let temp = [];
      for (let index = 0; index < this.totalPag; index++) {
        console.log(index);
        temp.push({
          id: index,
          active: false,
        });
      }
      temp[0].active = true;
      this.paginationList = temp;
    },

    async obtenerBlogs(id,band) {
      if(this.primeIndicator==true){
        let query = [];
        if(id == 0){
          query = await fr
        .collection("blog")
        .where("estatus", '==', 1)
        .where("prime", 'in', [0,1])
        .orderBy("fechaRegistro", 'desc')
        .limit(this.limit);
        } else {
          query = await fr
        .collection("blog")
        .where("estatus", '==', 1)
        .where("prime", 'in', [0,1])
        .where("filtro", '==', this.selectedType)
        .orderBy("fechaRegistro", 'desc')
        .limit(this.limit);
        }
      if (band) {
        if (this.lastPost != null) {
          query = query.startAfter(this.lastPost);
        }
      } else {
        query = query.startAt(this.firstPosts[this.pagActual - 1]);
      }

      query.get().then((querySnapshot) => {
        if (!querySnapshot.empty) {
          this.lastPost = querySnapshot.docs[querySnapshot.docs.length - 1];

          this.firstPosts.push(querySnapshot.docs[0]);

          let temp = [];
          querySnapshot.forEach((doc) => {
            // console.log(doc.id, " => ", doc.data());
            temp.push({
              id: doc.id,
              ...doc.data(),
            });
          });
          this.posts = temp;
        } else {
          console.info("colection empty");
        }
      });
      } else {
        let query = [];
        if(id == 0){
          query = await fr
        .collection("blog")
        .where("estatus", '==', 1)
        .where("prime", '==', 0)
        .orderBy("fechaRegistro", 'desc')
        .limit(this.limit);
        } else {
          query = await fr
        .collection("blog")
        .where("estatus", '==', 1)
        .where("prime", '==', 0)
        .where("filtro", '==', this.selectedType)
        .orderBy("fechaRegistro", 'desc')
        .limit(this.limit);

        this.lastPost = null;
        this.firstPosts = [];
        } 

      if (band) {
        if (this.lastPost != null) {
          query = query.startAfter(this.lastPost);
        }
      } else {
        query = query.startAt(this.firstPosts[this.pagActual - 1]);
      }

      query.get().then((querySnapshot) => {
        if (!querySnapshot.empty) {
          this.lastPost = querySnapshot.docs[querySnapshot.docs.length - 1];

          this.firstPosts.push(querySnapshot.docs[0]);

          let temp = [];
          querySnapshot.forEach((doc) => {
            // console.log(doc.id, " => ", doc.data());
            temp.push({
              id: doc.id,
              ...doc.data(),
            });
          });
          this.posts = temp;
        } else {
          console.info("colection empty");
        }
      });
      }
    },
  },
};
</script>

<style scoped>
 .date{
    color: white!important;
  }

  .resultados-text{
    font-family: Poppins;
    font-size: 1.4em;
    font-weight: 800;

  }

  .resultados-box{
    margin-top: 100px;

  }

 .text-filtro{
   color: #ab4deb;
   font-family: Poppins;
   font-weight: 800;
   margin-top: auto;
   margin-bottom: 8px;
  
 }
 .date{
   color: white;
 }

.searchbar{
    margin-bottom: auto;
    margin-top: auto;
    height: 60px;
    background-color: #353b48;
    border-radius: 30px;
    padding: 10px;
    width: 65%;
    }

    .search_input{
    color: white;
    padding: 0 10px;
    border: 0;  
    outline: 0;
    background: none;
    width: 60%;
    caret-color:transparent;
    line-height: 40px;
    transition: width 0.4s linear;
    }
    .searchbar:hover > .search_icon{
    background: white;
    color: #ab4deb;
    }

    ::-webkit-calendar-picker-indicator {
      filter: invert(1);
    }

    .search_icon{
    height: 40px;
    width: 40px;
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color:white;
    text-decoration:none;
    }

    .promo-wrapper {
      cursor: pointer;
    }

    .homepage-3 .single-promo h3 {
      font-size: 28px;
    }
    .active{
      background: none;
    }
    .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
      background-color: #e3d6ff;
      border-bottom: solid 5px #2f0c71;
      color: black;
    }

    .nav-link{
      font-size: 18px;
    }

    .encabezados{
      overflow: auto;
    }

    .chip {
    padding: 0 12px;
    background-color: rgba(175, 175, 175, 0.637);
    border-radius: 100px;
    display: inline-flex;
    margin: 4px;
    color: rgba(0, 0, 0, .87);
    align-items: center;
    height: 32px;
    font-size: 14px;
    cursor: pointer;
    border: none;
    }
    .chip .icon {
        font-size: 16px;
        color: rgba(0, 0, 0, .54);
        width: 20px;
        text-align: center;
    }

    .chip .icon--leadind {
        margin: 2px 4px 0 -4px;
    }

    .chip .icon--trailing {
        margin: 2px -4px 0 4px;
    }

    .chips__choice .chip.chip--active {
        color: white;
        background-color: #832b83;
    }

    .chips__filter .chip .chip--check {
        display: none;
    }

    .chips__filter .chip.chip--active .chip--check {
        display: block;
    }
</style>