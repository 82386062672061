<!--Esté es para el blog de articulos recientes y listas temáticas-->
<template>
  <div>
      <div class="filtros">
        <div class="d-flex justify-content-center mb-5">
          <h4 class="btn" @click="AbrirDocs()">Documentos relevantes</h4>
        </div>  

        <div class="d-flex justify-content-center" >
          <div class="encabezados">
            <ul class="nav nav-pills mb-5" id="pills-tab" role="tablist">
              <!--
              <li class="nav-item" role="presentation">
                <a class="nav-link active" id="pills-all-post" data-toggle="pill" href="#pills-all-post" role="tab"
                    aria-controls="pills-all-post" aria-selected="true" @click="SelectType(0)">
                  Todos
                </a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="nav-link" id="pills-newArti" data-toggle="pill" href="#pills-newArti" role="tab"
                    aria-controls="pills-newArti" aria-selected="false" @click="SelectType(1)">
                  Artículos Recientes
                </a>
              </li>-->
              <li class="nav-item" role="presentation">
                <a class="nav-link active" id="pills-lt" data-toggle="pill" href="#pills-lt" role="tab"
                    aria-controls="pills-lt" aria-selected="true" @click="SelectType(5)">
                  Listas Temáticas
                </a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="nav-link" id="pills-ss" data-toggle="pill" href="#pills-ss" role="tab"
                    aria-controls="pills-ss" aria-selected="false" @click="SelectType(2)">
                  Sesiones Sala Superior
                </a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="nav-link" id="pills-sx" data-toggle="pill" href="#pills-sx" role="tab"
                    aria-controls="pills-sx" aria-selected="false" @click="SelectType(3)">
                  Sesiones Sala Xalapa
                </a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="nav-link" id="pills-tev" data-toggle="pill" href="#pills-tev" role="tab"
                    aria-controls="pills-tev" aria-selected="false" @click="SelectType(4)">
                  Sesiones TEV
                </a>
              </li>
              <!--
              <li class="nav-item" role="presentation">
                <a class="nav-link" id="pills-otros" data-toggle="pill" href="#pills-otros" role="tab"
                    aria-controls="pills-otros" aria-selected="false" @click="SelectType(6)">
                  Otros
                </a>
              </li>-->
            </ul>
          </div>
        </div>
      </div>

    <div class="row">

      <div class="col-12 col-md-6 col-lg-4" v-if="primeIndicator==false">
        <div class="single-blog res-margin">
          <div class="blog-thumb">
            <img :src="href='https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/blog%2FSuscribeteElectoral.jpg?alt=media&token=6ee184ec-7818-443f-a601-ace12511f486'" />
          </div>
          <div class="blog-content">
            <ul class="meta-info d-flex justify-content-between">
            </ul>
            <h3 class="blog-title my-3" >Suscríbete a ElectoralTech & Legal</h3>
            <span

              ></span
            >
            <p>
              Conoce la proyección de lo que podría ser la próxima integración del Congreso de Veracruz.
              Además, descubre los detalles de los ajustes por partido que podrían darse con el fin de garantizar la paridad en la próxima integración del Congreso.
            </p>
            <a  class="blog-btn mt-3" style="color:#eb34cf; cursor: pointer" @click="alertPrime()" >SUSCRIBIRME</a>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4" v-for="p in posts" :key="p.id">
        <!-- Single Blog -->
        <div class="single-blog res-margin" v-if="!p.blogListaCheck">
          <!-- Blog Thumb -->
          <div class="blog-thumb">
            <img :src="p.image" :alt="p.titulo" />
          </div>
          <!-- Blog Content -->
          <div class="blog-content">
            <!-- Meta Info -->
            <ul class="meta-info d-flex justify-content-between">
              <li></li>
              <li>{{ fechaFormato(p.fechaRegistro) }}</li>
            </ul>
            <!-- Blog Title -->
            <h3 class="blog-title my-3">{{ p.titulo }}</h3>
            <span
              class="badge badge-secondary badge-pill"
              style="margin: 3px"
              v-for="cat in p.categorias"
              :key="cat.id"
              >{{ cat.texto }}</span
            >
            <p>
              {{ p.descripcion }}
            </p>
            <a class="blog-btn mt-3" style="cursor: pointer" @click="verBlog(p.id)">Leer más</a>
          </div>
        </div>

        <!-- List Blog -->
        <div class="single-blog res-margin" v-if="(p.blogListaCheck == true)">
          <!-- Blog Thumb -->
          <div class="blog-thumb">
            <img :src="p.image" :alt="p.titulo" />
          </div>
          <!-- Blog Content -->
          <div class="blog-content">
            <ul class="meta-info d-flex justify-content-between">
              
              <li>Última Actualización: {{ fechaFormato(p.fechaRegistro) }}</li>
            </ul> 
            <!-- Blog Title -->
            <h3 class="blog-title my-3">{{ p.titulo }}</h3>
            <p>
              {{ p.descripcion }}
            </p>
            <a  class="blog-btn mt-3" style="color:#14fc03; cursor: pointer" @click="showBlogList(p)" >Ver Lista</a>
          </div>
        </div>
      </div>

    </div>

    
    <Pagination 
      :pagination="paginationList"
      :posts="flyToNumber"
      :next="next"
      :previous="previous"
      class="mb-5"
    />
    
    <div 
          class="modal fade"
          id="ModalBlogList"
          role="dialog"
          aria-labelledby="ModalListaAyunta"
          aria-hidden="true"
          data-backdrop="true" 
          style="background-color: rgba(0, 0, 0, 0.5);"
      >
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <div class="row">
                    <div class="modal-header">
                        <h5 class="modal-title col-12 text-center textoTitulo">{{ModalBlogTitle}}</h5>
                    </div>
                    <div class="container-fluid d-flex justify-content-center mx-0">
                        <div class="containerModal">
                            <div v-for="(index, n) in blogListData.slice().reverse()" :key="n">
                                <button type="button" class="list-group-item list-group-item-action" @click="AbrirLink(index.itemUrl)">{{index.itemName}}</button>
                            </div>
                            <button type="button" class="list-group-item list-group-item-action" style="width: 100%;" @click="AbrirLink(firstBlogListData.url)">{{firstBlogListData.name}}</button>
                            
                        </div>
                    </div>
                    
                </div>
            </div>
          </div>
      </div>
  </div> 
</template>

<script>
import Pagination from "../Pagination/Pagination_vue";
/*import Pagination from "../Pagination/Pagination";*/
import { fr } from "../../../firebase";
import fn from '../../../libs/index';
import {auth} from "../../../firebase";
import { mapMutations } from "vuex";



export default {
  name: "BlogTwo",
  components: {
    Pagination,
  },
  mounted() {
    this.obtenerIdicadorPrime();
    //this.obtenerBlogs(0,true);
    this.obtenerPaginacion();
    this.actualizarUsuario();
    this.clickboton();
  },
  data() {
    return {
      pivotDocument: null,
      idCarrusel:null,
      paginationList: [],
      posts: [],
      postsPrime: [],
      lastPost: null,

      limit: 5,
      limit_1:5,
      numCarrusel:0,
      pagActual: 0,
      totalPag: 0,
      firstPosts: [],

      subscribePost:[],
      primeIndicator: false,
      selectedType : 0,


      ModalBlogTitle: "",
      firstBlogListData: {
        name: "", url: ""
      },
      blogListData: [],
      testVar: '',
    };
  },
  computed: {
    url() {
    return process.env.NODE_ENV == "development"
        ? this.urlLocal
        : this.urlProd; 
    },
  },
  methods: {
    ...mapMutations({
      setUsuario: "auth/setUsuario",
    }),
    async actualizarUsuario(){
      if ( auth.currentUser != null ) {
        const actualizaInfo = await fr
        .collection("usuarios")
        .doc(auth.currentUser.uid)
        .get()
        this.setUsuario(actualizaInfo.data())
        window.location.reload();
      }
    },

    AbrirLink(data){
      window.open(data);
    },

    AbrirDocs(){
      window.$("#ModalListaDocs").modal("show");
    },

    async SelectType(id){
      //ID's ---> 0 .- Todos , 5.- Listas Tematicas,  1.- Articulos Recientes , 2 .- Sala Xalapa , 3 .- Tribunal Electoral De Veracruz 4 .- Otros
      this.selectedType = id;
      this.lastPost = null;
      this.firstPosts = [];
      //this.obtenerBlogs(id,true);
      this.flyToNumber(0);
      this.obtenerPaginacion()
  
      },

    showListaNulidades(){
    },

    
    async alertPrime() {
      // window.$("#ModalCaptcha").modal("show");
      if ( auth.currentUser != null ) {
          this.suscribeModal();
      } else {
          const result = await this.$swal({
          icon: "info",
          title: "Registrate o Inicia sesión!",
          text: "Antes de convertirte en un miembro del servicio de información de ElectoralTech crea tu cuenta, es Gratis!",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showCancelButton: true,
          confirmButtonText: 'Crear cuenta ahora',
          });
          if (result.isConfirmed) {
          window.$("#registro").modal('show');
          } else if (result.isDenied) {
          this.$router.push({ name: "inicio" });
          console.log('no confirmo')
          }
      }
    },

    showBlogList(data){
      this.ModalBlogTitle = "";
      this.blogListData = [];
      this.firstBlogListData.name = "";
      this.firstBlogListData.url = "";

      this.blogListData = data.siguientesPDFs;     
      this.ModalBlogTitle = data.tituloLista;

      this.firstBlogListData.name = data.documentoTitulo;
      this.firstBlogListData.url = data.pdf;

      
      window.$("#ModalBlogList").modal("show");
      
      console.log("The DATA: ", this.blogListData);
    },

    showAyuntamientosList(){
      window.$("#ModalListaAyunta").modal("show");
    },
    showRPList(){
      window.$("#ModalListaRP").modal("show");
    },
    showParidadList(){
      window.$("#ModalListaParidad").modal("show");
    },

    suscribeModal(){
      window.$("#ModalPrime").modal("show");
    },
    
    verBlog(id){
      sessionStorage.setItem("currentBlog", id);
      this.$router.push({name:'blogDesc',});
    },
    fechaFormato(mil){
      return fn.fechaFormato(mil);
    },
    fechaFormato_2(mil){
      return fn.fechaFormato(mil);
    },
    obtenerIdicadorPrime(){
      if(sessionStorage.getItem("usuario")){
        const userData = JSON.parse(sessionStorage.getItem("usuario"));
        if(userData.prime==1){
          this.primeIndicator = true;
        }
        console.log("TU IDICADOR PRIME: "+ this.primeIndicator);
      }
    },

    clickboton(){
      document.querySelector('#pills-lt').click();
    },


    async flyToNumber(idCarrusel){
      //console.log("pagination_list",Math.round(this.paginationList[this.paginationList.length-1].id),"ThisCarrusel",idCarrusel,"Or",this.paginationList[this.paginationList.length-1].id);
      //if (Math.round(this.paginationList[this.paginationList.length-1].id)<this.limit_1 &Math.round(this.paginationList[this.paginationList.length-1].id)<this.totalPag-this.limit_1) {
        //console.log("GG",this.totalPag-Math.floor(this.limit_1/2),"idCarrusel",idCarrusel);
        if (idCarrusel>Math.floor(this.limit_1/2)-1 & idCarrusel<(this.totalPag-Math.floor(this.limit_1/2))+1) {
        //console.log("EntramosIf");
        this.obtenerPaginacion();
      }

      const querySnapshot = await fr
                      .collection("blog")
                      .where("estatus", '==', 1)
                      .where("prime", 'in', [0])
                      .where("filtro", '==', this.selectedType)
                      .orderBy("fechaRegistro", 'desc')
                      .get();
      //console.log("flyQuerySnapshot",querySnapshot);
      this.numCarrusel = idCarrusel;
      let query = [];
      query = await fr
            .collection("blog")
            .where("estatus", '==', 1)
            .where("prime", 'in', [0])
            .where("filtro", '==', this.selectedType)
            .orderBy("fechaRegistro", 'desc')
            .limit(this.limit);

      //console.log("FlyQuery",query);
      if (this.pagActual<this.numCarrusel) {

        if (this.lastPost != null) {
          this.pagActual=this.numCarrusel;
          //console.log("Multiplicación",(this.numCarrusel*(this.limit))-1)
          this.lastPost = querySnapshot.docs[(this.numCarrusel*(this.limit))-1];
          //console.log("this.lastPost != null",this.lastPost,"ThisNumCarrusel",this.numCarrusel);
          //console.log("FLYLastPost_3a",this.lastPost,"FLYQueryTrue_3a",query.get());
          query = query.startAfter(this.lastPost);
          //console.log("LastPost_3b",this.lastPost,"QueryTrue_3b",query.get());
        }
      } 
      else {
        //console.log("Else",((this.numCarrusel*(this.limit))-1))
      this.pagActual=this.numCarrusel;
      this.lastPost = querySnapshot.docs[(this.numCarrusel*(this.limit))-1];
      if (this.numCarrusel==0)
      {
        this.firstPosts.push(querySnapshot.docs[0]);
        query = query.startAt(this.firstPosts[this.numCarrusel]);
      }
      else{
        query = query.startAfter(this.lastPost);

      }
      //this.firstPosts.push(querySnapshot.docs[this.numCarrusel]);
    }
    /*
    for (let i= 0; i<querySnapshot.docs.length;i++)
      {
        console.log("flyQuerySnapshot_1",i,"-",querySnapshot.docs[i].id);
      }*/
      
      

      //console.log("queryGet",query.get())
      query.get().then((querySnapshot) => {
            if (!querySnapshot.empty) {
              this.lastPost = querySnapshot.docs[querySnapshot.docs.length - 1];
              
              this.firstPosts.push(querySnapshot.docs[0]);
              //console.log("FIRSTPOST-",querySnapshot.docs[0].id,"LASTPOST-",this,this.lastPost.id);

              let temp = [];
              querySnapshot.forEach((doc) => {
                //console.log("Segundoqueryy",doc.id, " => ", doc.data());
                temp.push({
                  id: doc.id,
                  ...doc.data(),
                });
              });
              this.posts = temp;
            } else {
              console.info("colection empty_fly");
            }
          });
          
          this.paginationList.forEach((pag) => {
          if (pag.id == this.numCarrusel) {
            //console.log("pagActive=True");
            pag.active = true;
          } else {
            //console.log("pagActive=False");
            pag.active = false;
          }
        });
       
    },

    next() {
      //console.log("paginaActual",this.pagActual+1,"totalPag",this.totalPag,"listapagination",this.paginationList);
      if ((this.pagActual +1) < this.totalPag) {
        /*
        this.pagActual++;
        console.log("paginaActual",this.pagActual+1,"totalPag",this.totalPag,"listapagination",this.paginationList);
        this.obtenerBlogs(this.selectedType,true);*/
        this.flyToNumber(this.numCarrusel+1)
        this.paginationList.forEach((pag) => {
          //console.log("pag",pag);
          if (pag.id == this.pagActual) {
            pag.active = true;
          } else {
            pag.active = false;
          }
        });
      }
    },
    previous() {
      //console.log("paginaActual",this.pagActual+1,"totalPag",this.totalPag,"listapagination",this.paginationList);
      if (this.pagActual >0 & this.pagActual<this.totalPag) {
        /*
        this.obtenerBlogs(this.selectedType,false);
        this.pagActual--;
        console.log("boton previus");*/
        this.flyToNumber(this.numCarrusel-1)
        
        this.paginationList.forEach((pag) => {
          if (pag.id == this.pagActual) {
            pag.active = true;
          } else {
            pag.active = false;
          }
        });
      }
    },
    async obtenerPaginacion() {
      /*let ListasTematicas =  [];*/
      const dbBlog = await fr.collection("blog");
      const query = dbBlog.where("filtro",'==', this.selectedType).where("estatus", '==', 1).where("prime", 'in', [0]);
      const snapshot = await query.get();

      //console.log("numberOfElements",snapshot.docs.length);
      this.totalPag = Math.round(snapshot.docs.length/this.limit);
      /*console.log("DataListas",numberOfPostTematicas.docs.length);*/
      //const querySnapshot = await fr.collection("blog").where("filtro",'==', this.selectedType).get();
      //console.log("estes es querysnapshot",querySnapshot)
      /*const totalBlog = querySnapshot.docs[0];*/
      /*this.totalPag = Math.ceil(totalBlog.data().blog / this.limit);*/
      /*console.log("total de paginas",this.totalPag,"limit",this.limit,"total blog",totalBlog.data());*/
      /*let temp = [];
      for (let index = 0; index < this.totalPag; index++) {
        //console.log(index);
        temp.push({
          id: index,
          active: false,
        });
      }
      temp[0].active = true;
      this.paginationList = temp;*/

      const indexInit = this.numCarrusel-Math.floor(this.limit_1/2);
      const indexEnd = this.numCarrusel+Math.ceil(this.limit_1/2);
      //console.log("IndexInit",indexInit,"IndexEnd",indexEnd,"carrusel",this.numCarrusel);
      let temp = [];
      //for (let index = 0; index < this.totalPag; index++) {
      for (let index = 0; index < this.totalPag; index++) {
        // console.log(index);
        temp.push({
          id: index,
          active: false,
        });
      }

      
    if (indexInit<0 & this.numCarrusel<Math.floor(this.limit/2)){
      //console.log("SIIII")
    this.paginationList = temp.slice(0,this.limit_1);
    this.paginationList[0].active = true;
  }
    else{
      //console.log("NOOO")
      this.paginationList = temp.slice(indexInit,indexEnd);
      this.paginationList[Math.floor(this.limit/2)].active = true;
    }
    },

    async obtenerBlogs(id,band) {
      if(this.primeIndicator==true){
        let query = [];
        if(id == 0){
            query = await fr
            .collection("blog")
            .where("estatus", '==', 1)
            .where("prime", 'in', [0,1])
            .where("filtro", '==',  this.selectedType)
            .orderBy("fechaRegistro", 'desc')
            .limit(this.limit);
          } else {
            query = await fr
            .collection("blog")
            .where("estatus", '==', 1)
            .where("prime", 'in', [0,1])
            .where("filtro", '==', this.selectedType)
            .orderBy("fechaRegistro", 'desc')
            .limit(this.limit);
            }
      if (band) {
        if (this.lastPost != null) {
          query = query.startAfter(this.lastPost);
          console.log("queryBandTrue_0",query,"lastPosBanderaTrue",this.lastPost);
        }
      } else {

        query = query.startAt(this.firstPosts[this.pagActual+1]);
        console.log("queryBandFalse_0",query);
      }

      query.get().then((querySnapshot) => {
        if (!querySnapshot.empty) {
          console.log("posts",querySnapshot.docs)
          this.lastPost = querySnapshot.docs[querySnapshot.docs.length - 1];
          console.log("thisLastPost",this.lastPost);
          this.firstPosts.push(querySnapshot.docs[0]);

          let temp = [];
          querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());
            temp.push({
              id: doc.id,
              ...doc.data(),
            });
          });
          this.posts = temp;
          console.log("queryBandTrue_1",query,"posts",this.posts);
        } else {
          console.info("colection empty");
        }
      });
      } 
        else {
            let query = [];
            if(id == 0){
              query = await fr
            .collection("blog")
            .where("estatus", '==', 1)
            .where("prime", 'in', [0])
            .where("filtro", '==', this.selectedType)
            .orderBy("fechaRegistro", 'desc')
            .limit(this.limit);
            } 
            else {
              query = await fr
            .collection("blog")
            .where("estatus", '==', 1)
            .where("prime", 'in', [0])
            .where("filtro", '==', this.selectedType)
            .orderBy("fechaRegistro", 'desc')
            .limit(this.limit);
            }
        if (band) {
          if (this.lastPost != null) {
            //console.log("LastPost_3a",this.lastPost,"QueryTrue_3a",query.get());
            query = query.startAfter(this.lastPost);
            //console.log("LastPost_3b",this.lastPost,"QueryTrue_3b",query.get());
          }
          } 
          else {
          //console.log("LastPost_3a",this.lastPost,"QueryFalse_3a",query.get());
          query = query.startAt(this.firstPosts[this.pagActual]);
          //console.log("LastPost_3b",this.lastPost,"QueryFalse_3b",query.get());
          }

          query.get().then((querySnapshot) => {
            if (!querySnapshot.empty) {
              this.lastPost = querySnapshot.docs[querySnapshot.docs.length - 1];
              this.firstPosts.push(querySnapshot.docs[0]);
              let temp = [];
              querySnapshot.forEach((doc) => {
                //console.log("Segundoqueryy",doc.id, " => ", doc.data());
                temp.push({
                  id: doc.id,
                  ...doc.data(),
                });
              });
              this.posts = temp;
            } else {
              console.info("colection empty");
            }
          });
      }
    },
  },
};
</script>

<style>
.modal-backdrop  {
  z-index: -1!important;
}

</style>

<style scoped>
 .date{
    color: white!important;
  }

  .resultados-text{
    font-family: Poppins;
    font-size: 1.4em;
    font-weight: 800;

  }

  .resultados-box{
    margin-top: 100px;

  }

 .text-filtro{
   color: #ab4deb;
   font-family: Poppins;
   font-weight: 800;
   margin-top: auto;
   margin-bottom: 8px;
  
 }
 .date{
   color: white;
 }

.searchbar{
    margin-bottom: auto;
    margin-top: auto;
    height: 60px;
    background-color: #353b48;
    border-radius: 30px;
    padding: 10px;
    width: 65%;
    }

    .search_input{
    color: white;
    padding: 0 10px;
    border: 0;  
    outline: 0;
    background: none;
    width: 60%;
    caret-color:transparent;
    line-height: 40px;
    transition: width 0.4s linear;
    }
    .searchbar:hover > .search_icon{
    background: white;
    color: #ab4deb;
    }

    ::-webkit-calendar-picker-indicator {
      filter: invert(1);
    }

    .search_icon{
    height: 40px;
    width: 40px;
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color:white;
    text-decoration:none;
    }

    .promo-wrapper {
      cursor: pointer;
    }

    .homepage-3 .single-promo h3 {
      font-size: 28px;
    }
    .active{
      background: none;
    }
    .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
      background-color: #e3d6ff;
      border-bottom: solid 5px #2f0c71;
      color: black;
    }

    .nav-link{
      font-size: 18px;
    }

    .encabezados{
      overflow: auto;
    }

    .chip {
    padding: 0 12px;
    background-color: rgba(175, 175, 175, 0.637);
    border-radius: 100px;
    display: inline-flex;
    margin: 4px;
    color: rgba(0, 0, 0, .87);
    align-items: center;
    height: 32px;
    font-size: 14px;
    cursor: pointer;
    border: none;
    }
    .chip .icon {
        font-size: 16px;
        color: rgba(0, 0, 0, .54);
        width: 20px;
        text-align: center;
    }

    .chip .icon--leadind {
        margin: 2px 4px 0 -4px;
    }

    .chip .icon--trailing {
        margin: 2px -4px 0 4px;
    }

    .chips__choice .chip.chip--active {
        color: white;
        background-color: #832b83;
    }

    .chips__filter .chip .chip--check {
        display: none;
    }

    .chips__filter .chip.chip--active .chip--check {
        display: block;
    }


    .modal-body {
      display: block !important;
    }

.btnCancel {
  padding: 9px;
  font-size: 14px;
  border: none;
  border-radius: 18px;
  margin: 2px;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');

body {
    background: #F5F1EE;
    font-family: 'Roboto', sans-serif
}

.price-text{
    font-family: 'Roboto';
    font-size: 1.2rem;
    font-weight: 800;
    background: -webkit-linear-gradient(#cf1773,#953ab1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
     color: tomato;
}

.card {
    width: 250px;
    border-radius: 10px
}

.card-img-top {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px
}

span.text-muted {
    font-size: 12px
}

small.text-muted {
    font-size: 8px
}

h5.mb-0 {
    font-size: 1rem
}

small.ghj {
    font-size: 9px
}

.mid {
    background: #ECEDF1
}

h6.ml-1 {
    font-size: 13px
}

small.key {
    text-decoration: underline;
    font-size: 9px;
    cursor: pointer
}

.btn-danger {
    color: #FFCBD2
}

.btn-danger:focus {
    box-shadow: none
}

small.justify-content-center {
    font-size: 9px;
    cursor: pointer;
    text-decoration: underline
}

@media screen and (max-width:600px) {
    .col-sm-4 {
        margin-bottom: 50px
    }
}
.containerModal{
    height:450px;
    overflow-y: auto;
    width: 90%;
}

.textoTitulo{
    font-size: 2rem;
    align-self: center;
    font-family: 'Roboto', sans-serif;
    background: -webkit-linear-gradient(#cf1773,#9b32a8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
     color: tomato;
}
.modal-header{
    margin-left: auto!important;
    margin-right: auto!important;
}
</style>