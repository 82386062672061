<template>
    <div 
        class="modal fade"
        id="ModalListaRP"
        tabindex="-1"
        role="dialog"
        aria-labelledby="ModalListaRP"
        aria-hidden="true"
    >
    <!--
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title col-12 text-center textoTitulo">DISTRIBUCION DE REGIDORES POR EL PRNCIPIO DE REPRESETACION PROPORCIONAL, LISTA DE AYUNTAMIENTOS:</h5>
          </div>
            <div class="container-fluid d-flex justify-content-center mx-0">
                <div class="container">
                    <button type="button" class="list-group-item list-group-item-action active pl-3" disabled>
                        Lista De Ayuntamientos En Veracruz
                    </button>
                    <div v-for="n in 212" :key="n">
                        <button type="button" class="list-group-item list-group-item-action">{{n }} - Nombre Del Ayuntamiento </button>
                    </div>
                </div>
            </div>
        </div>
        </div> -->
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="row">
                <div class="modal-header">
                    <h5 class="modal-title col-12 text-center textoTitulo">SENTENCIAS ASIGNACIÓN RP DIPUTADOS </h5>
                </div>
                <div class="container-fluid d-flex justify-content-center mx-0">
                    <div class="container">
                        <div v-for="(index, n) in sentenciasA" :key="n">
                            <button type="button" class="list-group-item list-group-item-action" @click="AbrirLink(index)">{{index.id }} - {{index.name}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="modal-header">
                    <h5 class="modal-title col-12 text-center textoTitulo">SENTENCIAS DE RP AYUNTAMIENTOS</h5>
                </div>
                <div class="container-fluid d-flex justify-content-center mx-0">
                    <div class="container">
                        <div v-for="(index, n) in sentenciasB" :key="n">
                            <button type="button" class="list-group-item list-group-item-action" @click="AbrirLink(index)">{{index.id }} - {{index.name}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
//import {auth , fr} from "../../../firebase";


export default {
    name: "ModalListaAyunta",
    data (){
        return{
            sentenciasA: [
                {id: 1, name: "DURANGO - SALA SUPERIOR CONFIRMA LA SENTÈNCIA DE SALA REGIONAL GUADALAJARA", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FLISTAAYUNTAMIENTOS%2FDURANGO-%20SUP_2021_REC_1400-CONFIRMA%20LA%20SENTENCIA%20DE%20SRG.pdf?alt=media&token=8e8b0f6d-e914-4e4b-84ff-bf5d7e386c55"},
                {id: 2, name: "DURANGO - TRIBUNAL LOCAL REVOCA EL ACUERDO", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FLISTAAYUNTAMIENTOS%2FDURANGO-SENTENCIA%20TEED-JE-082-2021%20Y%20ACUMULADOS-REVOCA%20EL%20ACUERDO%20IEPC-CG111-2021.pdf?alt=media&token=f89c0899-08e7-463a-a0f2-c1d19a28f935"},
                {id: 3, name: "DURANGO - SALA REGIONAL GUADALAJARA MODIFICA LA SENTENCIA DEL TRIBUNAL LOCAL", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FLISTAAYUNTAMIENTOS%2FDURANGO-SG_2021_JDC_853-MODIFICA%20LA%20SENTENCIA%20TEJE-082-21.pdf?alt=media&token=58f84901-f40f-4378-a9d0-80e570246c90"},
                {id: 4, name: "ESTADO DE MÉXICO -TRIBUNAL LOCAL MODIFICA EL ACUERDO NÚMERO IEEM/CG/150/2021", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FLISTAAYUNTAMIENTOS%2FESTADO%20DE%20M%C3%89XICO-JDCL-396-2021Acum-Sentencia%20Tribunal%20local.pdf?alt=media&token=1aa75695-5115-499d-b049-8bb5771be4e2"},
                {id: 5, name: "ESTADO DE MÉXICO - SALA TOLUCA MODIFICA LA SENTENCIA DEL TRIBUNAL LOCAL  ", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FLISTAAYUNTAMIENTOS%2FESTADO%20DE%20M%C3%89XICO-ST_2021_JRC_172-MODIFIC%C3%93%20LA%20SENTENCIA%20DEL%20TRIBUNAL%20LOCAL.pdf?alt=media&token=0f329561-639b-4bc1-986c-d9427a663cdc"},
                {id: 6, name: "ESTADO DE MÉXICO - SALA SUPERIOR MODIFICA LA SENTENCIA DE SALA REGIONAL TOLUCA", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FLISTAAYUNTAMIENTOS%2FESTADO%20DE%20M%C3%89XICO-SUP_2021_REC_1524-SE%20MODIFICA%20LA%20SENTENCIA%20RECLAMADA.pdf?alt=media&token=123da7b3-d5a1-48e5-a8c0-8ab9380d9aa1"},
                {id: 7, name: "AGUASCALIENTES - ACCIONES AFIRMATIVAS LGBTQ+ y PERSONAS CON DISCAPACIDAD", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FLISTAAYUNTAMIENTOS%2FAGUASCALIENTES%20DIP.LOC.Y.AYTO-ACCIONES%20AFIRMATIVAS.SM-JDC-0121-2021.pdf?alt=media&token=dca09cac-72d3-4c8a-a56b-15b3da09c4a3"},
                {id: 8, name: "DIPUTACIONES FEDERALES - ACCIONES AFIRMATIVAS (TODAS) ", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FLISTAAYUNTAMIENTOS%2FDIP-FED-ACCIONES%20AFIRMATIVAS-SUP-RAP-0047-2021%20VS%20ACUERDO%20INE-CG108-2021.pdf?alt=media&token=c5fbeb82-83e3-4aba-9f7d-acc7d112f7ad"},
                {id: 9, name: "HIDALGO - ACCIÓN AFIRMATIVA (PRIMERA INSTANCIA) LGBTTTIQ+ ", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FLISTAAYUNTAMIENTOS%2FHIDALGO-ACCI%C3%93N%20AFIRMATIVA1-TEEHJDC0252021_02-LGBTT...pdf?alt=media&token=2d16e542-56c7-4516-bd4b-f7464b5464cd"},
                {id: 10, name: "HIDALGO - ACCIÓN AFIRMATIVA (SEGUNDA INSTANCIA) LGBTTTIQ+", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FLISTAAYUNTAMIENTOS%2FHIDALGO-ACCI%C3%93N%20AFIRMATIVA1-TEEHJDC0252021_02-LGBTT...pdf?alt=media&token=2d16e542-56c7-4516-bd4b-f7464b5464cd"},
                {id: 11, name: "JALISCO - ACCIÓN AFIRMATIVA LGBTTTIQ+ Y PERSONAS CON DISCAPACIDAD.", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FLISTAAYUNTAMIENTOS%2FJALISCO-ACCI%C3%93N%20AFIR-JDC-012-2021-SOBRESEE%20POR%20HABER%20TRANSCURRIDO%20EL%20TIEMPO%20OPORTUNO.pdf?alt=media&token=41e86d1c-627d-4333-a731-4c19ab9b6ff1"},
                {id: 12, name: "NUEVO LEÓN - ACCIÓN AFIRMATIVA LGBTTTIQ+", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FLISTAAYUNTAMIENTOS%2FNUEVO%20LE%C3%93N.%20LGBTT.%20ACCIONES%20AFIR.JDC-033-2021%20AL%20JDC-040-2021.pdf?alt=media&token=815be22f-3818-4021-8a44-141312b2395e"},
                {id: 13, name: "TAMAULIPAS - SALA REGIONAL MODIFICA LA RESOLUCIÓN LOCAL Y HACE UNA NUEVA ASIGNACIÓN", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FLISTAAYUNTAMIENTOS%2FSM_2021_JRC_270-SALA%20MONTERREY%20MODIFICA%20LA%20RESOLUCI%C3%93N%20LOCAL%20Y%20HACE%20UNA%20NUEVA%20ASIGNACI%C3%93N%20%20DE%20DIP.%20POR%20RP.pdf?alt=media&token=5e41293f-076b-4509-917a-c750022a1646"},
                //{id: 00, name: "", url: ""},
            ],
            sentenciasB: [
                {id: 1, name: "LA PAZ, BAJA CALIFORNIA- SALA GUADALAJARA REVOCA LA SENTENCIA DEL TRIBUNAL LOCAL Y LA MODIFICACIÓN REALIZADA A LA ASIGNACIÓN DE REGIDURÍAS", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FLISTAAYUNTAMIENTOS%2FSG-JDC-0820-2021-%20SALA%20GUADALAJARA%20REVOCA%20LA%20SENTENCIA%20DEL%20TRIBUNAL%20LOCAL%20Y%20LA%20MODIFICACI%C3%93N%20REALIZADA%20A%20LA%20ASIGNACI%C3%93N%20DE%20REGIDUR%C3%8DAS%20DE%20LA%20PAZ%2C%20BCS.pdf?alt=media&token=99c705b5-2a2c-49cb-bbd6-9ee97c018838"},
                {id: 2, name: "AGUASCALIENTES - ACCIONES AFIRMATIVAS LGBTQ+ y PERSONAS CON DISCAPACIDAD", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FLISTAAYUNTAMIENTOS%2FAGUASCALIENTES%20DIP.LOC.Y.AYTO-ACCIONES%20AFIRMATIVAS.SM-JDC-0121-2021.pdf?alt=media&token=dca09cac-72d3-4c8a-a56b-15b3da09c4a3"},
                {id: 3, name: "JALISCO - ACCIÓN AFIRMATIVA LGBTTTIQ+ Y PERSONAS CON DISCAPACIDAD.", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FLISTAAYUNTAMIENTOS%2FJALISCO-ACCI%C3%93N%20AFIR-JDC-012-2021-SOBRESEE%20POR%20HABER%20TRANSCURRIDO%20EL%20TIEMPO%20OPORTUNO.pdf?alt=media&token=41e86d1c-627d-4333-a731-4c19ab9b6ff1"},
                {id: 4, name: "MEXICALI, BAJA CALIFORNIA SUR - TRIBUNAL LOCAL REVOCA ASIGNACIÓN DE REGIDURÍAS.", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FLISTAAYUNTAMIENTOS%2FRR-241-2021.MEXICALI%20SE%20REVOCA%20ASIGNACI%C3%93N%20DE%20RP%20DE%20REGIDUR%C3%8DAS..pdf?alt=media&token=8ae2618f-80e6-4d36-9fb3-462af78d6633"},
                {id: 4, name: "CAPULHUAC, EDO. DE MÉXICO - TRIBUNAL LOCAL MODIFICA LA ASIGNACIÓN DE RP", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FLISTAAYUNTAMIENTOS%2FJI702021Acum-CAPULHUAC%2C%20EDO.%20M%C3%89XICO.pdf?alt=media&token=df001f23-75bb-4c10-9b61-5ff130f0e038"},
                
                //{id: 1, name: "", url: ""}, 

            ],
            SelectedOption: "",
            captcha: false,
            urlProd:
                "https://us-central1-electoraltech-d9cec.cloudfunctions.net/webApi/",
            urlLocal:
                "http://localhost:5001/electoraltech-d9cec/us-central1/webApi/",
        }
    },
    computed: {
        url() {
        return process.env.NODE_ENV == "development"
            ? this.urlLocal
            : this.urlProd; 
        },
    },

    methods: {

        AbrirLink(data){
            window.open(data.url);
        },



        /*
        async pagarMes(){
            const user = auth.currentUser;
            const dt =  new Date().getTime()
            if (user !== null) {
                try{
                    const data = { 
                        uid: user.uid,
                        tipo: 1,
                        estado: 0,
                        voucherURL: "",
                        fechaSolicitud: dt,
                    };
                    console.log(data);
                    await fr.collection('suscripciones').doc(user.uid).set(data);
                    window.$("#ModalPrime").modal("hide");
                    const result = await this.$swal({
                    icon: "success",
                    title: "Hecho",
                    text: "Tu solicitud de suscripcion se ha generado correctamente, revisa tu perfil para ver las opciones de pago.",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showCancelButton: true,
                    confirmButtonText: 'aceptar',
                    });
                    if (result.isConfirmed) {
                    this.$router.push({ name: "perfil" });
                    } else if (result.isDenied) {
                    this.$router.push({ name: "inicio" });
                    console.log('no confirmo')
                    }
                    }catch (error) {
                        console.error(error);
                        this.$swal({
                        icon: "error",
                        title: "Revisa tu conexión a internet e intenta de nuevo",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        showCancelButton: true,
                        });
                    }      
            }else{
                const result = await this.$swal({
                icon: "warning",
                title: "Error inesperado",
                text: "Ha ocurrido un error inesperado, revisa tu conexion a internet y intentalo nuevamente mas tarde",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showCancelButton: true,
                confirmButtonText: 'aceptar',
                });
                if (result.isConfirmed) {
                this.$router.push({ name: "inicio" });
                } else if (result.isDenied) {
                this.$router.push({ name: "inicio" });
                console.log('no confirmo')
                }
            }
        } */
        
    },
    
}
</script>

<style scoped>
.modal-body {
  display: block !important;
}

.btnCancel {
  padding: 9px;
  font-size: 14px;
  border: none;
  border-radius: 18px;
  margin: 2px;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');

body {
    background: #F5F1EE;
    font-family: 'Roboto', sans-serif
}

.price-text{
    font-family: 'Roboto';
    font-size: 1.2rem;
    font-weight: 800;
    background: -webkit-linear-gradient(#cf1773,#9b32a8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
     color: tomato;
}

.card {
    width: 250px;
    border-radius: 10px
}

.card-img-top {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px
}

span.text-muted {
    font-size: 12px
}

small.text-muted {
    font-size: 8px
}

h5.mb-0 {
    font-size: 1rem
}

small.ghj {
    font-size: 9px
}

.mid {
    background: #ECEDF1
}

h6.ml-1 {
    font-size: 13px
}

small.key {
    text-decoration: underline;
    font-size: 9px;
    cursor: pointer
}

.btn-danger {
    color: #FFCBD2
}

.btn-danger:focus {
    box-shadow: none
}

small.justify-content-center {
    font-size: 9px;
    cursor: pointer;
    text-decoration: underline
}

@media screen and (max-width:600px) {
    .col-sm-4 {
        margin-bottom: 50px
    }
}
.container{
    height:450px;
    overflow-y: auto;
}

.textoTitulo{
    font-size: 2rem;
    font-family: 'Roboto', sans-serif;
    background: -webkit-linear-gradient(#cf1773,#9b32a8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
     color: tomato;

}

</style>

