<template>
    <div 
        class="modal fade"
        id="ModalListaDocs"
        tabindex="-1"
        role="dialog"
        aria-labelledby="ModalListaDocs"
        aria-hidden="true"
    >
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header justify-content-center">
                <h5 class="modal-title col-12  text-center textoTitulo">DOCUMENTOS RELEVANTES</h5>
            </div>
            
            <div id="tablaDocumentos">
                <input type="text" id="palabraTabla" v-on:keyup="buscarEnDocs(search)" placeholder="Búsqueda en documentos relevantes.." v-model="search">
                <table id="DocsAndFileTable">
                    <!--
                    <tr class="header">
                        <th style="width:60%">Documentos y archivos</th>
                    </tr>
                    -->
                    <div v-for="(index, n) in resultados" :key="n">
                        
                        <button type="button" class="list-group-item list-group-item-action" @click="AbrirLink(index)">
                            <tr>
                                <td>{{index.titulo_doc }}</td>
                            </tr>
                            
                        </button>
                        
                    </div>

                </table>

            </div>
            <!--
            <div class="row">
                <div class="container-fluid d-flex justify-content-center mx-0">
                    <div class="container">
                        <div v-for="(index, n) in resultados" :key="n">
                            <button type="button" class="list-group-item list-group-item-action" @click="AbrirLink(index)">{{index.titulo_doc }}</button>
                        </div>
                    </div>
                </div>
            </div>
            -->
        </div>
    </div>
    </div>
</template>

<script>
import {fr} from "../../../firebase";
import fn from "../../../libs/index";
import Vue from 'vue';

new Vue({
    el: 'tablaDocumentos',
    data: () => ({
            headers: [
                { text: "Link", align: "start", value: "id" },
                { text: "Título", align: "start", value: "titulo_doc" },
                { text: "Descripción", align: "start", value: "descripcion" },
            ],
            
            data: [],
            //cambioStatus: false,
            //blogListaCheck: false,

            tituloLista: "",
            documentoTitulo: "",

            listFile: [],
            listFileTempUrl: "",
            dialogAddFile: false,
            addFileTitle: "",

            docToAddFile: "",
            resultados: [],
            sentenciasB: [
                //{id: 1, name: "", url: ""}, 
            ],

            SelectedOption: "",
            captcha: false,
            urlProd:
                "https://us-central1-electoraltech-d9cec.cloudfunctions.net/webApi/",
            urlLocal:
                "http://localhost:5001/electoraltech-d9cec/us-central1/webApi/",
        
    }),
    mounted(){

    },
})

export default {

    name: "ModalListaDocs",
    mounted(){
        this.ObtenerDatos();
        this.obtenerPosts();
        this.buscarEnDocs();
        
        
    },
    data (){
        return{
            search: "",
            headers: [
                { text: "Link", align: "start", value: "id" },
                { text: "Título", align: "start", value: "titulo" },
                { text: "Descripción", align: "start", value: "descripcion" },
            ],
            
            data: [],
            //cambioStatus: false,
            //blogListaCheck: false,

            tituloLista: "",
            documentoTitulo: "",

            listFile: [],
            listFileTempUrl: "",
            dialogAddFile: false,
            addFileTitle: "",

            docToAddFile: "",
            resultados: [],
            sentenciasB: [
                //{id: 1, name: "", url: ""}, 
            ],

            SelectedOption: "",
            captcha: false,
            urlProd:
                "https://us-central1-electoraltech-d9cec.cloudfunctions.net/webApi/",
            urlLocal:
                "http://localhost:5001/electoraltech-d9cec/us-central1/webApi/",
        }
    },
    computed: {
        url() {
        return process.env.NODE_ENV == "development"
            ? this.urlLocal
            : this.urlProd; 
        },
    },
    methods: {
    buscarEnDocs(){
    var input, filter, table, tr, td, i, txtValue;
    input = document.getElementById("palabraTabla");
    filter = input.value.toUpperCase();
    table = document.getElementById("DocsAndFileTable");
    tr = table.getElementsByTagName("button");

    // Loop through all table rows, and hide those who don't match the search query
    for (i = 0; i < tr.length; i++) {
        td = tr[i].getElementsByTagName("td")[0];
        if (td) {
        txtValue = td.textContent || td.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
            tr[i].style.display = "";
        } else {
            tr[i].style.display = "none";
        }
        }   
  }
},

        async ObtenerDatos(){
            var resultados = [];
            resultados = await fr
            .collection("documentosRef")
            .get()
            .then(snapshot  =>  {
                snapshot.docs.forEach(resultado =>{
                let currentID = resultado.id
                let appObj = {...resultado.data(), ['id']: currentID }
                resultados.push(appObj)
                });
                this.resultados = resultados;
                //this.data = resultados;
            });
            console.log(this.resultados);
            console.log("HAHAAOHAHOA");
        },
        AbrirLink(data){
            window.open("https://electoraltech.com.mx/Documents/?document_id="+data.id);
        },

    async obtenerPosts() {
      try {
        this.setLoading(true);
        const snapshot = await fr.collection("blog").orderBy("fechaRegistro").get();
        if (!snapshot.empty) {
          let temp = [];
          snapshot.forEach((doc) => {
            temp.push({
              id: "https://electoraltech.com.mx/blog-post/?blog_id="+doc.id,
              docid: doc.id,
              ...doc.data(),
              fechaRegistro: fn.fechaFormato(doc.data().fechaRegistro),
            });
          });
          this.data = temp;
          console.log("Este es data:",this.data);
        }
        this.setLoading(false);
      } catch (error) {
        console.log("ERROR");
        console.warn(error);
        this.setLoading(false);
      }
    },
        /*
        async pagarMes(){
            const user = auth.currentUser;
            const dt =  new Date().getTime()
            if (user !== null) {
                try{
                    const data = { 
                        uid: user.uid,
                        tipo: 1,
                        estado: 0,
                        voucherURL: "",
                        fechaSolicitud: dt,
                    };
                    console.log(data);
                    await fr.collection('suscripciones').doc(user.uid).set(data);
                    window.$("#ModalPrime").modal("hide");
                    const result = await this.$swal({
                    icon: "success",
                    title: "Hecho",
                    text: "Tu solicitud de suscripcion se ha generado correctamente, revisa tu perfil para ver las opciones de pago.",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showCancelButton: true,
                    confirmButtonText: 'aceptar',
                    });
                    if (result.isConfirmed) {
                    this.$router.push({ name: "perfil" });
                    } else if (result.isDenied) {
                    this.$router.push({ name: "inicio" });
                    console.log('no confirmo')
                    }
                    }catch (error) {
                        console.error(error);
                        this.$swal({
                        icon: "error",
                        title: "Revisa tu conexión a internet e intenta de nuevo",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        showCancelButton: true,
                        });
                    }      
            }else{
                const result = await this.$swal({
                icon: "warning",
                title: "Error inesperado",
                text: "Ha ocurrido un error inesperado, revisa tu conexion a internet y intentalo nuevamente mas tarde",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showCancelButton: true,
                confirmButtonText: 'aceptar',
                });
                if (result.isConfirmed) {
                this.$router.push({ name: "inicio" });
                } else if (result.isDenied) {
                this.$router.push({ name: "inicio" });
                console.log('no confirmo')
                }
            }
        } */
        
    }
}




</script>

<style scoped>
.modal-body {
  display: block !important;
}

.btnCancel {
  padding: 9px;
  font-size: 14px;
  border: none;
  border-radius: 18px;
  margin: 2px;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');

body {
    background: #F5F1EE;
    font-family: 'Roboto', sans-serif
}

.price-text{
    font-family: 'Roboto';
    font-size: 1.2rem;
    font-weight: 800;
    background: -webkit-linear-gradient(#cf1773,#9b32a8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
     color: tomato;
}

.card {
    width: 250px;
    border-radius: 10px
}

.card-img-top {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px
}

span.text-muted {
    font-size: 12px
}

small.text-muted {
    font-size: 8px
}

h5.mb-0 {
    font-size: 1rem
}

small.ghj {
    font-size: 9px
}

.mid {
    background: #ECEDF1
}

h6.ml-1 {
    font-size: 13px
}

small.key {
    text-decoration: underline;
    font-size: 9px;
    cursor: pointer
}

.btn-danger {
    color: #FFCBD2
}

.btn-danger:focus {
    box-shadow: none
}

small.justify-content-center {
    font-size: 9px;
    cursor: pointer;
    text-decoration: underline
}

@media screen and (max-width:600px) {
    .col-sm-4 {
        margin-bottom: 50px
    }
}
.container{
    height:450px;
    overflow-y: auto;
}

.textoTitulo{
    font-size: 2rem;
    font-family: 'Roboto', sans-serif;
    background: -webkit-linear-gradient(#cf1773,#9b32a8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
     color: tomato;

}
.tablaDocumentos {
    font-size: 1rem;
    color: #cf1773;
    align-content: center;
    align-items: center;
    display: flex;
    margin: 0px 0 1px 40px;
}
#palabraTabla {
  background-image: url('/css/searchicon.png'); /* Add a search icon to input */
  background-position: 10px 12px; /* Position the search icon */
  background-repeat: no-repeat; /* Do not repeat the icon image */
  width: 100%; /* Full-width */
  font-size: 16px; /* Increase font-size */
  padding: 12px 20px 12px 40px; /* Add some padding */
  border: 1px solid #ddd; /* Add a grey border */
  margin-bottom: 12px; /* Add some space below the input */
}
</style>

