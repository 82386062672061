<template>
    <div class="row">
        <div class="col-12">
            <!-- Pagination -->
            <ul class="pagination justify-content-center">
                <li class="disabled px-1">
                    <a aria-label="Previous" @click="previous">
                        <i class="fas fa-arrow-left"></i>
                        Regresar
                    </a>
                </li>
                <li v-for="p in pagination" :key="p.id" :class="{'px-1':true, 'active': p.active}">
                    <a>{{ p.id +1 }}</a>
                </li>

                <!-- <li class="px-1 active "><a>1</a></li>
                <li class="px-1"><a>2</a></li>
                <li class="px-1"><a>3</a></li> -->
                <li>
                    <a aria-label="Next" @click="next">
                        <i class="fas fa-arrow-right"></i>
                        Siguiente
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
/*import VuepPaginate from 'vue-paginate'
Vue.use(VuepPaginate)*/

export default {
    props:{
        pagination: Array,
        next:Function,
        previous: Function
    },
    name: 'Pagination',
    mounted(){
        console.info(this.pagination)
    },
    data() {
        return {

        }
    }
}
</script>

<style>
    li {
        cursor: pointer;
    }
</style>