<template>
    <div 
        class="modal fade"
        id="ModalListaParidad"
        tabindex="-1"
        role="dialog"
        aria-labelledby="ModalListaParidad"
        aria-hidden="true"
    >
    <!--
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title col-12 text-center textoTitulo">DISTRIBUCION DE REGIDORES POR EL PRNCIPIO DE REPRESETACION PROPORCIONAL, LISTA DE AYUNTAMIENTOS:</h5>
          </div>
            <div class="container-fluid d-flex justify-content-center mx-0">
                <div class="container">
                    <button type="button" class="list-group-item list-group-item-action active pl-3" disabled>
                        Lista De Ayuntamientos En Veracruz
                    </button>
                    <div v-for="n in 212" :key="n">
                        <button type="button" class="list-group-item list-group-item-action">{{n }} - Nombre Del Ayuntamiento </button>
                    </div>
                </div>
            </div>
        </div>
        </div> -->
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="row">
                <div class="modal-header">
                    <h5 class="modal-title col-12 text-center textoTitulo">SENTENCIAS PARIDAD CÁMARA DE DIPUTADOS FEDERAL Y LOCALES </h5>
                </div>
                <div class="container-fluid d-flex justify-content-center mx-0">
                    <div class="container">
                        <div v-for="(index, n) in sentenciasA" :key="n">
                            <button type="button" class="list-group-item list-group-item-action" @click="AbrirLink(index)">{{index.id }} - {{index.name}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <!--
            <div class="row">
                <div class="modal-header">
                    <h5 class="modal-title col-12 text-center textoTitulo">SENTENCIAS PARIDAD 2...</h5>
                </div>
                <div class="container-fluid d-flex justify-content-center mx-0">
                    <div class="container">
                        <div v-for="(index, n) in sentenciasB" :key="n">
                            <button type="button" class="list-group-item list-group-item-action" @click="AbrirLink(index)">{{index.id }} - {{index.name}}</button>
                        </div>
                    </div>
                </div>
            </div>
            -->
        </div>
        </div>
    </div>
</template>

<script>
//import {auth , fr} from "../../../firebase";


export default {
    name: "ModalListaAyunta",
    data (){
        return{
            sentenciasA: [
                {id: 0, name: "SALA SUPERIOR - PARIDAD EN GOBERNATURAS", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FLISTA-PARIEDAD%2FSUP-RAP-116-2020-ENGROSE%20SGA_PARIDAD.pdf?alt=media&token=fe7408d2-a5ac-4946-bd84-9b3e7910d362"}, 
                {id: 1, name: "DIPUTADOS FEDERALES - SALA SUPERIOR MODIFICA EL ACUERDO INE/CG1443/2021.", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FLISTA-PARIEDAD%2FSUP_2021_REC_1414-DIPUTADOS%20FEDERALES%20RP-MODIFICA%20EL%20ACUERDO%20CONTROVERTIDO.pdf?alt=media&token=26e2606e-30ac-4a03-9bee-4eca7c9b918b"},
                {id: 2, name: "HIDALGO - SALA SUPERIOR REVOCA LA SENTENCIA IMPUGNADA Y EN PLENITUD DE JURISDICCIÓN REALIZA LA ASIGNACIÓN", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FLISTA-PARIEDAD%2FSUP_2021_REC_1540-PARIDAD%20HIDALGO.pdf?alt=media&token=2f95f646-6b8a-4286-9e4c-32db6596116b"},
                {id: 3, name: "CIUDAD DE MÉXICO - SE MODIFICA LA RESOLUCIÓN DE SALA REGIONAL", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FLISTA-PARIEDAD%2FSUP-REC-1423-2021-PARIDAD%20CDMX.pdf?alt=media&token=65ee98ca-60d7-45d2-a032-2175c1ac63b1"},
                {id: 4, name: "CHIHUAHUA (PRIMERA INSTANCIA) - REALIZA LA ASIGNACIÓN DE DIPUTACIONES", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FLISTA-PARIEDAD%2FCHIHUAHUA1-PARIDAD-DIP.LOC.05_Sentencia-JDC-475_2021-y-acumulados.pdf?alt=media&token=8394d7ad-4520-43f3-a0bb-a7046bcb56ed"},
                {id: 5, name: "CHIHUAHUA (SEGUNDA INSTANCIA) - SALA REGIONAL REVOCA LA RESOLUCIÓN DEL TRIBUNAL LOCAL", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FLISTA-PARIEDAD%2FCHIHUAHUA2-PARIDAD-DIP-LOC.SG_2021_JDC_897.pdf?alt=media&token=547a4a8e-842f-462b-b2f2-e53aa922811e"},
                {id: 6, name: "ESTADO DE MÉXICO -  SALA REGIONAL MODIFICA LA SENTENCIA DEL TRIBUNAL LOCAL QUE MODIFICO EL ACUERDO IEEM/CG/150/2021", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FLISTA-PARIEDAD%2FESTADO%20DE%20M%C3%89XICO1-ASIG%20RP%20DIP%20LOC.ST-JRC-0172-2021%20sentencia%20Edo.%20M%C3%A9xico.pdf?alt=media&token=bf96f666-e655-4084-a439-2e0093ab9061"},
                {id: 7, name: "SAN LUIS POTOSÍ -  SALA SUPERIOR DESECHA EL RECURSO Y CONFIRMA LA SENTENCIA RECLAMADA", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FLISTA-PARIEDAD%2FSLP-PARIDAD-SUP-REC-1560-2021.pdf?alt=media&token=7094fef3-ab92-44e7-b6c7-79304e6ffbc4"},
                {id: 8, name: "HIDALGO - SALA SUPERIOR REVOCA LA SENTENCIA DE LA SALA REGIONAL ", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FLISTA-PARIEDAD%2FSUP_2021_REC_1540-PARIDAD%20HIDALGO.pdf?alt=media&token=dbde0aaa-1569-49f0-a060-60666af6ebcb"},
                {id: 9, name: "CIUDAD DE MÉXICO - SALA SUPERIOR MODIFICA SENTENCIA DE SALA REGIONAL", url: "https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/ElectoralSearch%2FOPEN-BLOGS-DATA%2FLISTA-PARIEDAD%2FSUP_2021_REC_1540-PARIDAD%20HIDALGO.pdf?alt=media&token=dbde0aaa-1569-49f0-a060-60666af6ebcb"},
                
                //{id: 00, name: "", url: ""}, 
            ],
            sentenciasB: [
                //{id: 00, name: "", url: ""},
                
            ],
            SelectedOption: "",
            captcha: false,
            urlProd:
                "https://us-central1-electoraltech-d9cec.cloudfunctions.net/webApi/",
            urlLocal:
                "http://localhost:5001/electoraltech-d9cec/us-central1/webApi/",
        }
    },
    computed: {
        url() {
        return process.env.NODE_ENV == "development"
            ? this.urlLocal
            : this.urlProd; 
        },
    },

    methods: {

        AbrirLink(data){
            window.open(data.url);
        },



        /*
        async pagarMes(){
            const user = auth.currentUser;
            const dt =  new Date().getTime()
            if (user !== null) {
                try{
                    const data = { 
                        uid: user.uid,
                        tipo: 1,
                        estado: 0,
                        voucherURL: "",
                        fechaSolicitud: dt,
                    };
                    console.log(data);
                    await fr.collection('suscripciones').doc(user.uid).set(data);
                    window.$("#ModalPrime").modal("hide");
                    const result = await this.$swal({
                    icon: "success",
                    title: "Hecho",
                    text: "Tu solicitud de suscripcion se ha generado correctamente, revisa tu perfil para ver las opciones de pago.",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showCancelButton: true,
                    confirmButtonText: 'aceptar',
                    });
                    if (result.isConfirmed) {
                    this.$router.push({ name: "perfil" });
                    } else if (result.isDenied) {
                    this.$router.push({ name: "inicio" });
                    console.log('no confirmo')
                    }
                    }catch (error) {
                        console.error(error);
                        this.$swal({
                        icon: "error",
                        title: "Revisa tu conexión a internet e intenta de nuevo",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        showCancelButton: true,
                        });
                    }      
            }else{
                const result = await this.$swal({
                icon: "warning",
                title: "Error inesperado",
                text: "Ha ocurrido un error inesperado, revisa tu conexion a internet y intentalo nuevamente mas tarde",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showCancelButton: true,
                confirmButtonText: 'aceptar',
                });
                if (result.isConfirmed) {
                this.$router.push({ name: "inicio" });
                } else if (result.isDenied) {
                this.$router.push({ name: "inicio" });
                console.log('no confirmo')
                }
            }
        } */
        
    },
    
}
</script>

<style scoped>
.modal-body {
  display: block !important;
}

.btnCancel {
  padding: 9px;
  font-size: 14px;
  border: none;
  border-radius: 18px;
  margin: 2px;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');

body {
    background: #F5F1EE;
    font-family: 'Roboto', sans-serif
}

.price-text{
    font-family: 'Roboto';
    font-size: 1.2rem;
    font-weight: 800;
    background: -webkit-linear-gradient(#cf1773,#9b32a8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
     color: tomato;
}

.card {
    width: 250px;
    border-radius: 10px
}

.card-img-top {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px
}

span.text-muted {
    font-size: 12px
}

small.text-muted {
    font-size: 8px
}

h5.mb-0 {
    font-size: 1rem
}

small.ghj {
    font-size: 9px
}

.mid {
    background: #ECEDF1
}

h6.ml-1 {
    font-size: 13px
}

small.key {
    text-decoration: underline;
    font-size: 9px;
    cursor: pointer
}

.btn-danger {
    color: #FFCBD2
}

.btn-danger:focus {
    box-shadow: none
}

small.justify-content-center {
    font-size: 9px;
    cursor: pointer;
    text-decoration: underline
}

@media screen and (max-width:600px) {
    .col-sm-4 {
        margin-bottom: 50px
    }
}
.container{
    height:450px;
    overflow-y: auto;
}

.textoTitulo{
    font-size: 2rem;
    font-family: 'Roboto', sans-serif;
    background: -webkit-linear-gradient(#cf1773,#9b32a8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
     color: tomato;

}

</style>

